import { Button } from '@nextui-org/react'
import { RedoDot } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Error404() {
  const navigate = useNavigate()
  return (
    <div className={'flex flex-1 w-full h-full items-center justify-center flex-col p-40 text-center gap-2'}>
      <img src={'/img/powerdocs-logo-signet.png'} alt={'logo'} className={'h-[150px] mb-3'} />
      <h1 className={'text-4xl font-medium'}>Phew, you found it.</h1>
      <h2 className={'text-md font-light text-default-400'}>
        You came here, looking for something, and all you get is our 404 page. The page you are looking for is no longer
        here.
        <br />
        Maybe it was never here in the first place. In any case, we are sorry you were sent on this wild 🪿 goose chase.
      </h2>
      <Button
        color="secondary"
        endContent={<RedoDot />}
        size="md"
        radius="md"
        className={'p-5'}
        onClick={() => navigate('/')}
      >
        Back to home page
      </Button>
    </div>
  )
}
