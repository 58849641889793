import React from 'react'
import { BreadcrumbItem, Breadcrumbs, Button, Card, CardBody, CardFooter, CardHeader } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'

export default function SettingsLanding() {
  const navigate = useNavigate()
  return (
    <div className={'p-6 w-full'}>

      <Breadcrumbs>
        <BreadcrumbItem onClick={() => navigate('/')}>
          <Icon icon={'lucide:home'} /> Home
        </BreadcrumbItem>
        <BreadcrumbItem>Settings</BreadcrumbItem>
      </Breadcrumbs>

      <div className="flex w-full pt-8 pb-6 2xl:px-8">
        <header className="flex w-full justify-between">
          <div className="flex flex-col">
            <div className="flex place-items-center">
              <h1 className="text-xl font-bold text-default-900 lg:text-3xl mr-2">Settings</h1>
            </div>
            <p className="text-small text-default-700 lg:text-medium">
                Manage and customize your settings to enhance your experience
            </p>
          </div>
        </header>
      </div>

      <div className="flex flex-col gap-8 pt-8 max-w-[750px]">
        <div className="grid grid-cols-2 gap-4">
          <div
            className={`flex flex-col gap-3 border-2 p-4 rounded-lg cursor-pointer border-default-200`}
            onClick={() => navigate('/settings/profile')}
          >
            <div className="border rounded-xl bg-default-100 border-none p-3 w-fit">
              <Icon icon="lucide:user" width="20" />
            </div>
            <div>
              <h3 className="text-lg font-medium">Profile</h3>
              <p className="text-sm text-default-500">
              Change your account settings here.
              </p>
            </div>
            <div className="flex items-center gap-1 text-secondary uppercase text-tiny font-semibold">
              Get started <Icon icon="lucide:arrow-right" width="20" />
            </div>
          </div>
          <div
            className={`flex flex-col gap-3 border-2 p-4 rounded-lg cursor-pointer border-default-200`}
            onClick={() => navigate('/settings/company')}
          >
            <div className="border rounded-xl bg-default-100 border-none p-3 w-fit">
              <Icon icon="lucide:shapes" width="20" />
            </div>
            <div>
              <h3 className="text-lg font-medium">Company details</h3>
              <p className="text-sm text-default-500">
                These details are displayed in your documents at the title page, footer and as contact details.
              </p>
            </div>
            <div className="flex items-center gap-1 text-secondary uppercase text-tiny font-semibold">
              Get started <Icon icon="lucide:arrow-right" width="20" />
            </div>
          </div>
          <div
            className={`flex flex-col gap-3 border-2 p-4 rounded-lg cursor-pointer border-default-200`}
            onClick={() => navigate('/settings/powerdocs')}
          >
            <div className="border rounded-xl bg-default-100 border-none p-3 w-fit">
              <Icon icon="lucide:sparkles" width="20" />
            </div>
            <div>
              <h3 className="text-lg font-medium">Personalize Powerdocs AI</h3>
              <p className="text-sm text-default-500">
                Enter your preferences for the style and tone in a which your documents should be created.
              </p>
            </div>
            <div className="flex items-center gap-1 text-secondary uppercase text-tiny font-semibold">
              Get started <Icon icon="lucide:arrow-right" width="20" />
            </div>
          </div>
          <div
            className={`flex flex-col gap-3 border-2 p-4 rounded-lg cursor-pointer border-default-200`}
            onClick={() => navigate('/settings/billing')}
          >
            <div className="flex justify-between">
              <div className="border rounded-xl bg-default-100 border-none p-3 w-fit">
                <Icon icon="lucide:credit-card" width="20" />
              </div>
            </div>
            <div>
              <h3 className="text-lg font-medium">Billing details</h3>
              <p className="text-sm text-default-500">
                Enter your billing details here.
              </p>
            </div>
            <div className="flex items-center gap-1 text-secondary uppercase text-tiny font-semibold">
              Get started <Icon icon="lucide:arrow-right" width="20" />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
