import { NodeViewWrapper } from '@tiptap/react'

export const CoverPageComponent = ({ node, getPos, editor, updateAttributes }) => {
  return (
    <NodeViewWrapper className="cover-page-section relative">
      <div className={'cover-page flex flex-col'} contentEditable={false}>
        <div
          style={{
            display: 'table',
            minHeight: '60%',
          }}
        >
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
              paddingBottom: '20%',
            }}
          >
            <div
              className="date"
              contentEditable={true}
              suppressContentEditableWarning
              onInput={(e) => handleContentChange(0, e.currentTarget?.textContent ?? '')}
            >
              {date}
            </div>
            <div
              className="main-title"
              contentEditable={true}
              suppressContentEditableWarning
              onInput={(e) => handleContentChange(1, e.currentTarget?.textContent ?? '')}
            >
              {title}
            </div>
          </div>
        </div>
        <div className="page-info">
          <table className={'w-full'}>
            <tr className={'w-full'}>
              <td
                style={{
                  width: '50%',
                  display: 'table-cell',
                  verticalAlign: 'top',
                }}
              >
                <div className="section">
                  <div className="section-title">Created by:</div>
                  <div className="section-content">
                    <strong
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(2, e.currentTarget?.textContent ?? '')}
                    >
                      {yourName}
                    </strong>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(3, e.currentTarget?.textContent ?? '')}
                    >
                      {yourAgencyRole}
                    </span>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(4, e.currentTarget?.textContent ?? '')}
                    >
                      {yourAgency}
                    </span>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(5, e.currentTarget?.textContent ?? '')}
                    >
                      {yourPhone}
                    </span>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(6, e.currentTarget?.textContent ?? '')}
                    >
                      {yourEmail}
                    </span>
                    <br />
                  </div>
                </div>
              </td>
              <td
                style={{
                  width: '50%',
                  display: 'table-cell',
                  verticalAlign: 'top',
                }}
              >
                <div className="section">
                  <div className="section-title">Prepared for:</div>
                  <div className="section-content">
                    <strong
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(7, e.currentTarget?.textContent ?? '')}
                    >
                      {clientName}
                    </strong>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(8, e.currentTarget?.textContent ?? '')}
                    >
                      {clientRole}
                    </span>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(9, e.currentTarget?.textContent ?? '')}
                    >
                      {clientAgency}
                    </span>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(10, e.currentTarget?.textContent ?? '')}
                    >
                      {clientPhone}
                    </span>
                    <br />
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning
                      onInput={(e) => handleContentChange(11, e.currentTarget?.textContent ?? '')}
                    >
                      {clientEmail}
                    </span>
                    <br />
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </NodeViewWrapper>
  )
}
