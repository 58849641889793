import { Icon } from '@iconify/react'
import { PRICES } from 'src/lib/constants'
import Mixpanel from 'src/lib/mixpanel'
import { ModalHeader, ModalBody, Button, Modal, ModalContent } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import api from 'src/lib/api'
interface SubscribeLimitDocumentProps {
  onClose: () => void
  isOpen: boolean
}
export function SubscribeLimitDocument({ onClose, isOpen }: SubscribeLimitDocumentProps) {
  const [profile, setPorfile] = useState<any>({})

  const loadUser = () => {
    const userString = localStorage.getItem('user')
    if (userString) {
      setPorfile(JSON.parse(userString))
    }
    api
      .get('/agencies/profile')
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setPorfile(res.data)
      })
      .catch((e) => {
        console.log('Error loading current profile')
      })
  }
  useEffect(() => {
    loadUser()
  }, [])
  return (
    <Modal
      className={'no-scrollbar'}
      backdrop="blur"
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size="4xl"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1"></ModalHeader>
            <ModalBody className={'scrollbar-hide px-2 md:px-20 pb-20 pt-10 gap-0'}>
              <div
                className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded mb-5"
                role="alert"
              >
                <p className="font-bold">Limit reached</p>
                <p>You have reached your document creation limit for this month. Upgrade now to unlock unlimited creations and access additional benefits.</p>
              </div>
              <h2 className={'text-xl  text-center text-secondary uppercase'}>Pricing</h2>
              <h1 className={'text-4xl  text-center mb-4 font-bold'}>Pricing made easy</h1>
              <p className={'text-sm text-default-400 w-full text-center mb-4'}>
                Flexible plans designed for everyone, offering simplicity and convenience
              </p>
              <div className={'flex flex-row gap-4'}>
                <div className={'flex flex-col flex-1 border rounded-2xl border-default-300 p-4'}>
                  <span className={'text-xs font-light text-default-400'}>Always free</span>
                  <h3 className={'text text-3xl font-bold'}>Free</h3>
                  <p className="py-2">
                    <span className="text-[30px] font-semibold leading-7 text-default-foreground">$0</span>
                    &nbsp;<span className="text-xs font-medium text-default-400">/ per month</span>
                  </p>
                  <span className={'font-bold text-black text-sm pb-2'}>What's included</span>
                  <ul className="list-inside list-none text-md font-light text-black">
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" /> Create up to 3 documents
                      per month
                    </li>
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" />
                      AI-powered autocomplete
                    </li>
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" />
                      Questions Picker to spot and fill gaps
                    </li>
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" />
                      Use AI Assistant for full document edits
                    </li>
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" />
                      Export to PDF, Word, and CSV
                    </li>
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" />
                      Invite team members (viewers only)
                    </li>
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" />
                      Share links with Powerdocs branding
                    </li>
                  </ul>
                  <Button
                    variant={'bordered'}
                    className={'rounded-3xl font-bold text-sm w-fit pl-10 pr-10 self-center mt-4'}
                    color={'secondary'}
                  >
                    Your current plan
                  </Button>
                </div>

                <div className={'flex flex-col flex-1 border border-3 rounded-2xl border-secondary p-4'}>
                  <span className={'text-xs font-light text-default-400'}>Unlock everything</span>
                  <h3 className={'text text-3xl font-bold'}>Pro</h3>
                  <p className="py-2">
                    <span className="text-[30px] font-semibold leading-7 text-default-foreground">$29</span>
                    &nbsp;<span className="text-xs font-medium text-default-400">/ month per editor</span>
                  </p>
                  <span className={'font-bold text-black text-sm pb-2'}>What's included</span>
                  <ul className="list-inside list-none text-md font-light text-black">
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" /> Create{' '}
                      <b>unlimited documents</b> per month
                    </li>
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" />
                      AI-powered autocomplete
                    </li>
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" />
                      Questions Picker to spot and fill gaps
                    </li>
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" />
                      Use AI Assistant for full document edits
                    </li>
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" />
                      Export to PDF, Word, and CSV
                    </li>
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" />
                      Invite <b>editors</b> with <b>real-time</b> collaboration
                    </li>
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" />
                      Share links <b>without</b> Powerdocs branding
                    </li>
                    <li className={'flex flex-row items-center gap-1 mb-1'}>
                      <Icon className={'text-secondary'} icon="icon-park-solid:check-one" />
                      Access team & client analytics and audit logs
                    </li>
                  </ul>
                  <Button
                    onClick={() => {
                      const plan = 'PRO'
                      const link = PRICES?.[plan]?.paymentLink
                      // Track payment intent
                      Mixpanel.track('Payment plan selected', {
                        selected_plan: plan,
                      })

                      try {
                        const userData = JSON.parse(localStorage.getItem('user') as string)
                        window.open(link + '?prefilled_email=' + userData?.email)
                        onClose()
                      } catch (e) {}
                    }}
                    variant={'solid'}
                    className={'rounded-3xl font-bold text-sm w-fit pl-10 pr-10 self-center mt-4'}
                    color={'secondary'}
                  >
                    Get Started
                  </Button>
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
