import { Button, Divider, Slider, Listbox, ListboxItem } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import { useState } from 'react';

import Mixpanel from 'src/lib/mixpanel'

export default function DesignContent({ onClose, docDesignSettings, setDocDesignSettings }) {
  const [sliderValue, setSliderValue] = useState(2);
  const [inputValue, setInputValue] = useState(sliderValue.toString());
  
  const fonts = [
    { name: "Bitter", family: "'Bitter', serif" },
    { name: "Comfortaa", family: "'Comfortaa', cursive" },
    { name: "Georgia", family: "'Georgia', serif" },
    { name: "Inter", family: "'Inter', sans-serif" },
    { name: "Lexend", family: "'Lexend', sans-serif" },
    { name: "Lora", family: "'Lora', serif" },
    { name: "Montserrat", family: "'Montserrat', sans-serif" },
    { name: "Oswald", family: "'Oswald', sans-serif" },
  ];
  
  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-col sticky top-0 bg-white">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-medium">Design & Font</h2>
          <Button isIconOnly variant="light" onPress={onClose} size="sm">
            <Icon icon="ic:round-close" className="text-gray-500 text-xl" />
          </Button>
        </div>
        <Divider className="my-2 h-[3px]" />
      </div>

      <div className="flex flex-col justify-between items-center mb-4 gap-4">
        <div className="mt-4 w-full">
          <p className="text-sm text-gray-500 mb-2">FONT STYLE</p>
          <div className="w-full border-small px-1 py-2 rounded-small border-default-200 dark:border-default-100">
            <Listbox
              aria-label="Font Selection"
              disallowEmptySelection
              selectionMode="single"
              selectedKeys={[docDesignSettings.fontType]}
              onSelectionChange={(keys) => {
                const selectedFont = [...keys][0];
                setDocDesignSettings((prev) => ({ ...prev, fontType: selectedFont }));

                Mixpanel.track('Font type changed', {
                  font_type: selectedFont,
                  page_name: window.location.pathname,
                  location: 'Document Sidebar - Design & Font',
                });
              }}
            >
              {fonts.map((font) => (
                <ListboxItem key={font.name} textValue={font.name} className="cursor-pointer">
                  <div className="flex justify-between items-center w-full text-xl">
                    <span style={{ fontFamily: font.family }}>{font.name}</span>
                  </div>
                </ListboxItem>
              ))}
            </Listbox>
          </div>
        </div>
        
        <Divider className="my-4 h-[3px]" />

        <div className="flex flex-col w-full gap-8">
          <Slider
            minValue={1}
            maxValue={3}
            step={1}
            defaultValue={2}
            value={docDesignSettings.fontSize}
            onChange={(value) => {
              setDocDesignSettings((prev) => ({ ...prev, fontSize: value }));

              Mixpanel.track('Font size changed', {
                font_size: value === 1 ? 'SMALL' : value === 2 ? 'MEDIUM' : 'LARGE',
                page_name: window.location.pathname,
                location: 'Document Sidebar - Design & Font',
              });
            }}
            aria-label="Font size"
            className="w-full cursor-pointer"
            color="secondary"
            label="FONT SIZE"
            size="sm"
            showSteps={true}
            startContent={<Icon icon="material-symbols:text-decrease-rounded" width="25" className="text-gray-500" />}
            endContent={<Icon icon="material-symbols:text-increase-rounded" width="25" className="text-gray-500" />}
            renderValue={({ children, ...props }) => (
              <output {...props}>
                {/* Custom Label */}
                {(() => {
                  const labels = { 1: 'SMALL', 2: 'MEDIUM', 3: 'LARGE' };
                  return labels[docDesignSettings.fontSize] || 'Unknown';
                })()}
              </output>
            )}
          />

          <Slider
            minValue={1}
            maxValue={7}
            step={1}
            defaultValue={4}
            value={docDesignSettings.sectionSpacing}
            onChange={(value) => {
              setDocDesignSettings((prev) => ({ ...prev, sectionSpacing: value }));

              Mixpanel.track('Section spacing changed', {
                section_spacing: value,
                page_name: window.location.pathname,
                location: 'Document Sidebar - Design & Font',
              });
            }}
            aria-label="Section spacing"
            className="w-full cursor-pointer"
            color="secondary"
            label="SECTION SPACING"
            size="sm"
            showSteps={true}
            startContent={<Icon icon="solar:list-down-bold" width="25" className="text-gray-500" />}
            endContent={<Icon icon="solar:list-up-bold" width="25" className="text-gray-500" />}
          />
        </div>
      </div>
    </div>
  );
}