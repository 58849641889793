/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useEffect, useRef, useState } from 'react'
import { NodeViewContent, NodeViewWrapper, NodeViewProps } from '@tiptap/react'
import { Editor } from '@tiptap/core'
import Tippy from '@tippyjs/react'

import './styles.scss'
import { Icon } from '@iconify/react'
import { TextSelection } from '@tiptap/pm/state'

interface CellButton {
  name: string
  action: (editor: Editor) => boolean
  iconClass?: string
}

const cellButtonsConfig = [
  {
    title: 'Row actions',
    actions: [
      {
        name: 'Add row above',
        action: (editor: Editor) => eeditor.chain().focus().addRowBefore().run(),
        iconClass: 'material-symbols:add-row-above-outline',
      },
      {
        name: 'Add row below',
        action: (editor: Editor) => editor.chain().focus().addRowAfter().run(),
        iconClass: 'material-symbols:add-row-below-outline',
      },
      {
        name: 'Remove row',
        action: (editor: Editor) => editor.chain().focus().deleteRow().run(),
        iconClass: 'mdi:table-row-remove',
        buttonClass: '!text-red-600',
      },
    ],
  },
  {
    title: 'Column actions',
    actions: [
      {
        name: 'Add column left',
        action: (editor: Editor) => editor.chain().focus().addColumnBefore().run(),
        iconClass: 'mdi:table-column-add-before',
      },
      {
        name: 'Add column right',
        action: (editor: Editor) => editor.chain().focus().addColumnAfter().run(),
        iconClass: 'mdi:table-column-add-after',
      },
      {
        name: 'Remove column',
        action: (editor: Editor) => editor.chain().focus().deleteColumn().run(),
        iconClass: 'mdi:table-column-remove',
        buttonClass: '!text-red-600',
      },
    ],
  },
]

export const TableCellNodeView: FC<NodeViewProps> = ({ node, getPos, selected, editor }) => {
  const [isCurrentCellActive, setIsCurrentCellActive] = useState(false)
  const [cellOffset, setCellOffset] = useState(0)
  const controllers = useRef<HTMLElement | null>(null)

  useEffect(() => {
    const updateHeaderState = () => {
      const { state } = editor
      const { doc } = state
      const position = getPos()
      if (position === undefined) return

      const resolvedCell = state.doc.resolve(getPos())
      const currentCellIndex = resolvedCell.parent.content?.content?.findIndex(
        (item) => item === resolvedCell.nodeAfter,
      )
      const rowSizeFromStartToCell = resolvedCell.parent.content?.content
        ?.slice(0, currentCellIndex)
        .reduce((sp, a) => sp + a.nodeSize, 0)
      const resolvedTableRow = doc.resolve(getPos() - rowSizeFromStartToCell - 1)
      const parentTable = resolvedTableRow.parent
      const rowIndex = parentTable.content.content?.findIndex((n) => n === resolvedTableRow.nodeAfter)

      const sizeForTableHead = parentTable.content.content?.slice(0, rowIndex).reduce((rp, a) => rp + a.nodeSize, 0)
      const resolvedTable = doc.resolve(resolvedTableRow.pos - sizeForTableHead - 1)
      const tableDomElement = editor.view.nodeDOM(resolvedTable.pos)
      const rowDomElement = editor.view.nodeDOM(resolvedTableRow.pos)

      if (tableDomElement && rowDomElement) {
        if (rowDomElement.getBoundingClientRect && tableDomElement.getBoundingClientRect) {
          const { top: rowTopPos } = rowDomElement.getBoundingClientRect()
          const { top: tableTopPos } = tableDomElement.getBoundingClientRect()
          const offset = rowTopPos - tableTopPos
          setCellOffset(offset)
        }
      }
    }

    updateHeaderState()
    editor.on('transaction', updateHeaderState)

    return () => {
      editor.off('transaction', updateHeaderState)
    }
  }, [editor, getPos])

  const showControls = () => {
    if (controllers.current) {
      controllers.current.classList.remove('hidden')
    }
  }

  const hideControls = () => {
    if (controllers.current) {
      controllers.current.classList.add('hidden')
    }
  }

  const calculateActiveSateOfCurrentCell = () => {
    const { from, to } = editor.state.selection

    const nodeFrom = getPos()
    const nodeTo = nodeFrom + node.nodeSize

    setIsCurrentCellActive(nodeFrom <= from && to <= nodeTo)
  }
  useEffect(() => {
    editor.on('selectionUpdate', calculateActiveSateOfCurrentCell)

    setTimeout(calculateActiveSateOfCurrentCell, 100)

    return () => {
      editor.off('selectionUpdate', calculateActiveSateOfCurrentCell)
    }
  }, [editor])

  useEffect(() => {
    const cellElement = controllers.current?.closest('.table-cell')

    const handleMouseEnter = () => {
      showControls()
    }

    const handleMouseLeave = (event: MouseEvent) => {
      if (cellElement && !cellElement.contains(event.relatedTarget as Node)) {
        hideControls()
      }
    }

    if (controllers.current) {
      controllers.current.addEventListener('mouseenter', handleMouseEnter)
      controllers.current.addEventListener('mouseleave', handleMouseLeave)
    }

    return () => {
      if (controllers.current) {
        controllers.current.removeEventListener('mouseenter', handleMouseEnter)
        controllers.current.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [controllers])

  return (
    <NodeViewWrapper as="div" className="h-full tiptap-cell" onMouseEnter={showControls} onMouseLeave={hideControls}>
      <span>
        <NodeViewContent as="span" />
        {editor.isEditable && (
          <>
            <div
              ref={controllers}
              contentEditable={false}
              className="absolute left-0 right-0 mx-auto hidden flex items-center bg-black rounded-full w-[55px] min-w-[55px] max-w-[55px] z-50 cursor-pointer justify-center h-[20px]"
              style={{ top: -1 * cellOffset - 10 }}
            >
              <section className="w-fit flex text-xs text-white gap-1">
                <button
                  className="opacity-75 hover:opacity-100"
                  onClick={() => {
                    editor.chain().focus().moveLeft(getPos(), node).run()
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
                <button
                  className="opacity-75 hover:opacity-100"
                  onClick={() => {
                    const { state } = editor
                    const { tr } = state
                    // Resolve the position of the selected cell
                    const resolvedCell = state.doc.resolve(getPos())
                    tr.setSelection(new TextSelection(resolvedCell))
                    editor.view.dispatch(tr)
                    editor.chain().focus().deleteColumn().run()
                  }}
                >
                  <i className="fas fa-trash"></i>
                </button>
                <button
                  className="opacity-75 hover:opacity-100"
                  onClick={() => {
                    editor.chain().focus().moveRight(getPos(), node).run()
                  }}
                >
                  <i className="fas fa-arrow-right"></i>
                </button>
              </section>
            </div>
            {(isCurrentCellActive || selected) && (
              <Tippy
                appendTo={document.body}
                trigger="click"
                interactive
                animation="shift-toward-subtle"
                placement="auto"
                content={
                  <article className="dropdown" contentEditable={false}>
                    <ul className="dropdown-content fixed menu menu-compact p-2 bg-base-100 rounded-xl shadow-xl border border-default-300 w-56 gap-2">
                      {cellButtonsConfig.map((category) => (
                        <li key={category.title}>
                          <h3 className="font-bold text-sm mb-1">{category.title}</h3>
                          {category.actions.map((btn) => (
                            <button
                              key={btn.name}
                              className={`button flex items-center text-left ${btn.buttonClass || ''}`}
                              onClick={() => btn.action(editor)}
                            >
                              <Icon icon={btn.iconClass as string} width={20} />
                              <span>{btn.name}</span>
                            </button>
                          ))}
                        </li>
                      ))}
                    </ul>
                  </article>
                }
              >
                <label tabIndex={0} className="trigger-button" contentEditable={false}>
                  <Icon icon="heroicons-outline:chevron-down" />
                </label>
              </Tippy>
            )}
          </>
        )}
      </span>
    </NodeViewWrapper>
  )
}
