import { Button, Divider, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import api from 'src/lib/api'
import { useWorkspaceContextData } from 'src/context/workspace'
import { useNavigate } from 'react-router-dom'

export default function EditClientModal({
  openedEditClient,
  onOpenEditClientChange,
  clientToEdit,
}: {
  openedEditClient: boolean
  onOpenEditClientChange: (isOpen: boolean) => void
  clientToEdit: any
}) {
  const [creatingClient, setCreatingClient] = useState<boolean>(false)
  const [name, setName] = useState(clientToEdit?.name ?? '')
  const [agencyName, setAgencyName] = useState<string>(clientToEdit?.agencyName ?? '')
  const [role, setAgencyRole] = useState<string>(clientToEdit?.agencyRole ?? '')
  const [phone, setPhone] = useState(clientToEdit?.phone ?? '')
  const [email, setEmail] = useState(clientToEdit?.email ?? '')
  const { editClient } = useWorkspaceContextData()
  const navigate = useNavigate()
  const createClient = async () => {
    if (name.length === 0 || creatingClient) return
    setCreatingClient(true)
    const res = await api.post('/clients/' + clientToEdit?.uuid, {
      name,
      role,
      phone,
      email,
      agencyName,
    })
    editClient?.(res.data)
    setCreatingClient(false)
    onOpenEditClientChange(false)
    setName('')
    setAgencyRole('')
    setAgencyName('')
    setPhone('')
    setEmail('')
    navigate(`/client/${res.data?.uuid}`)
  }
  useEffect(() => {
    if (clientToEdit) {
      setName(clientToEdit.name ?? '')
      setAgencyName(clientToEdit.agencyName ?? '')
      setPhone(clientToEdit.phone ?? '')
      setEmail(clientToEdit.email ?? '')
      setAgencyRole(clientToEdit.role ?? '')
    }
  }, [clientToEdit])
  return (
    <Modal size={'3xl'} isOpen={openedEditClient} onOpenChange={onOpenEditClientChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1"></ModalHeader>
            <ModalBody>
              <div className="flex flex-col grow gap-7">
                <div className="flex flex-col items-center">
                  <p className="text-2xl font-semibold">Edit client</p>
                  <p className="text-base text-center">Update your client Information</p>
                </div>
                <div className="flex flex-col gap-3 items-center">
                  <Input
                    isRequired
                    type="text"
                    label="Client name"
                    placeholder="e.g. John Doe"
                    className="w-full"
                    onChange={(e) => setName(e.target.value)}
                    autoFocus={true}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && name?.length > 0) {
                        createClient()
                      }
                    }}
                    value={name}
                  />
                  <Divider orientation="horizontal" />
                  <div className={'flex w-full flex-row gap-2'}>
                    <Input
                      type="text"
                      value={agencyName}
                      label="Agency Name"
                      placeholder="e.g. ACM Inc"
                      className="flex flex-1"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && name?.length > 0) {
                          createClient()
                        }
                      }}
                      onChange={(e) => setAgencyName(e.target.value)}
                    />
                    <Input
                      type="text"
                      value={role}
                      label="Client Role"
                      placeholder="e.g. CEO"
                      className="flex flex-1"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && name?.length > 0) {
                          createClient()
                        }
                      }}
                      onChange={(e) => setAgencyRole(e.target.value)}
                    />
                  </div>
                  <div className={'flex w-full flex-row gap-2'}>
                    <Input
                      type="text"
                      value={email}
                      label="Client E-mail"
                      placeholder="e.g. john.doe@acme.io"
                      className="flex flex-1"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && name?.length > 0) {
                          createClient()
                        }
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                      type="text"
                      value={phone}
                      label="Client Phone"
                      placeholder="e.g. +1(234) 5678-9000"
                      className="flex flex-1"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && name?.length > 0) {
                          createClient()
                        }
                      }}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button color="secondary" onPress={createClient} isDisabled={name.length === 0 || creatingClient}>
                Update Information
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
