import { cn, Button, useDisclosure, Textarea, Spinner } from '@nextui-org/react'

import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSocketContextData } from 'src/context/socket'

import api, { baseURL } from 'src/lib/api'
import { CheckIcon } from 'lucide-react'
import { TipTapEditor } from 'src/pages/ursPage/extensions/tiptap'

import { useWorkspaceContextData } from 'src/context/workspace'
import DocumentPreview from 'src/pages/ursPage/DocumentPreview'
import DocSidebar from 'src/pages/ursPage/DocSidebar'

import FeedbackBar from './FeedbackBar'
import Mixpanel from 'src/lib/mixpanel'

const CollapsibleDiv = (props) => {
  const [isOpen, setIsOpen] = useState(false) // State to manage the open/close status
  const [answer, setAnswer] = useState(props.userResponse ?? '')
  // Toggle function to open/close the div
  const toggleDiv = () => {
    setIsOpen((prev) => !prev)
  }
  return (
    <div className="container mx-auto cursor-pointer">
      <div
        onClick={toggleDiv}
        className={'px-2 py-3 rounded-xl border-1'}
        style={
          isOpen
            ? {
                backgroundColor: '#ededed',
              }
            : {}
        }
      >
        <div className={'flex flex-row items-center gap-3'}>
          <div
            className={[
              'flex border-default-500 border-1 justify-center items-center align-middle rounded-full w-[34px] h-[34px]',
              isOpen ? 'border-2 border-secondary' : '',
              props.userResponse ? 'bg-secondary border-secondary' : isOpen ? 'bg-transparent ' : '',
            ].join(' ')}
          >
            {props.userResponse ? (
              <span className={'text-white'}>
                <CheckIcon />
              </span>
            ) : (
              <h3 className={`text-md ${isOpen ? 'text-secondary' : 'text-default-600'} font-medium `}>
                {props.number}
              </h3>
            )}
          </div>
          <div className={'flex flex-1 flex-col text-left'}>
            <h3 className={'text-md font-medium'}>{props.section || props.question}</h3>
            {props.section && <span className={'text-sm font-regular text-default-600'}>{props.question}</span>}
          </div>
        </div>
        <div
          className={`mt-4 transition-all duration-500 ease-in-out ${isOpen ? 'visible' : 'hidden'} overflow-hidden`}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className="pl-4 flex flex-col text-left" style={{ marginLeft: 29 }}>
            {props.suggestions?.map((suggestion: string) => (
              <Button
                variant={answer === suggestion ? 'solid' : 'bordered'}
                color="secondary"
                className={[
                  'text-left font-medium text-black border-default-400 border-1 mb-2 justify-start',
                  answer === suggestion ? 'text-white border-secondary' : '',
                ].join(' ')}
                onPress={() => {
                  setAnswer(suggestion)
                }}
                style={{
                  whiteSpace: 'normal',
                  textAlign: 'left',
                  height: 'auto',
                  minHeight: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                <span>{suggestion.replace('- ', '')}</span>
              </Button>
            ))}
            <Textarea
              variant={'bordered'}
              placeholder={'Enter your own answer in keywords …'}
              defaultValue={props.suggestions?.indexOf(props.userResponse) > -1 ? '' : props.userResponse}
              classNames={{
                inputWrapper: 'border-1 border-default-400',
              }}
              onChange={(e) => setAnswer(e.target.value)}
              onKeyDown={(e) => {
                if ((e.shiftKey || e.metaKey) && e.key === 'Enter') {
                  e.preventDefault()
                  props.onQuestionAnswer(e.target.value)
                  toggleDiv()
                }
              }}
            />
            <Button
              color={'secondary'}
              variant={'solid'}
              className={'w-auto mt-2 block self-end pl-4 pr-4 text-white'}
              onPress={() => {
                props.onQuestionAnswer(answer)
                toggleDiv()
              }}
            >
              Add to document
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default function UrsPage({
  isReadOnly,
  variant,
}: {
  isReadOnly?: boolean
  variant: 'quotation' | 'proposal' | 'offer'
}) {
  const navigate = useNavigate()
  const { activeWorkspace } = useWorkspaceContextData()
  const { t } = useTranslation()
  const { ursUUID } = useParams()
  const { io } = useSocketContextData()
  const { isOpen: isOpenFinal, onOpen: onOpenFinal, onClose: onCloseFinal } = useDisclosure()

  const [doc, setDoc] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [initialized, setInitizalied] = useState<boolean>(false)
  const [ursDocument, setUrsDocument] = useState<string>('')
  const [tableContent, setTableContent] = useState<any[]>([])
  const [documentLoading, setDocumentLoading] = useState(false)
  const [isAISidebarOpen, setIsAISidebarOpen] = useState<boolean>(false)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [showAIAssistant, setShowAIAssistant] = useState<boolean>(false)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [currentActiveWorkspace, setCurrentActiveWorkspace] = useState(activeWorkspace ?? '')
  const [editorInstance, setEditorInstance] = useState(null)
  const [docDesignSettings, setDocDesignSettings] = useState({
    fontType: 'Inter',
    fontSize: 2, // 1 = small, 2 = medium, 3 = large
    sectionSpacing: 4, // between 1-7
    skipUpdate: true,
  })

  const crepRef = useRef(null)
  const tiptapEditorRef = useRef(null)

  useEffect(() => {
    if (tiptapEditorRef.current) {
      setEditorInstance(tiptapEditorRef.current)
    }
  }, [tiptapEditorRef.current])

  const downloadPdf = () => {
    const popup = window.open(`${baseURL}/urs/export/${doc.uuid}`)
    if (popup) {
      popup?.focus()
      popup.addEventListener('unload', () => {
        popup.close()
      })
    }
  }
  const loadURS = async () => {
    const res = await api.get(`/urs/${ursUUID}`)
    setDoc(res.data)
    setDocDesignSettings(
      res.data?.designSetting ?? {
        fontType: 'Inter',
        fontSize: 2, // 1 = small, 2 = medium, 3 = large
        sectionSpacing: 4, // between 1-7
      },
    )
    let content
    switch (variant) {
      case 'quotation':
        content = res.data?.quotation
        break
      case 'offer':
        content = res.data?.offer
        break
      case 'proposal':
        content = res.data?.document
        break
    }
    if (content) {
      setUrsDocument(content)
      tryChangeUrs(content, 5)
    }
    setInitizalied(true)
  }
  const tryChangeUrs = async (content: string, tryNumber: number) => {
    if (tryNumber > 0) {
      try {
        if (crepRef && crepRef.current?.isReady?.()) {
          crepRef.current?.updateMarkdown(content)
        } else {
          setTimeout(() => tryChangeUrs(content, tryNumber - 1), 500)
        }
      } catch (err) {
        console.log('trying to create urs number ', tryNumber)
        setTimeout(() => tryChangeUrs(content, tryNumber - 1), 500)
      }
    }
  }

  useEffect(() => {
    if (doc) {
      const ursListInChat = doc?.chat
        ?.filter((item) => item.content.includes('# *User Requirements Document (URD)*'))
        .pop()
      let content
      switch (variant) {
        case 'quotation':
          content = doc.quotation
          break
        case 'offer':
          content = doc.offer
          break
        case 'proposal':
          content = doc.document
          break
      }
      if (ursListInChat) {
        setUrsDocument(content ?? ursListInChat.content)
        tryChangeUrs(content ?? ursListInChat.content, 5)
      } else if (content) {
        setUrsDocument(content)
        tryChangeUrs(content, 5)
      }
    }
  }, [doc, variant])

  useEffect(() => {
    loadURS()
  }, [])

  useEffect(() => {
    if (activeWorkspace !== currentActiveWorkspace) {
      navigate('/urs')
    }
  }, [activeWorkspace])

  useEffect(() => {
    if (io) {
      io.on('document_chunk', (data) => {
        const documentString = data.document.split('@@QUESTIONS@@')[0].replace(/@@URD@@/g, '')
        setUrsDocument(documentString)
        setDoc({
          ...doc,
          document: documentString,
        })
      })
      io.on('proposal_chunk', (data) => {
        const documentString = data.document.split('@@QUESTIONS@@')[0].replace(/@@URD@@/g, '')
        setUrsDocument(documentString)
        setDoc({
          ...doc,
          document: documentString,
        })
      })
      io.on('quotation_chunk', (data) => {
        const documentString = data.document.split('@@QUESTIONS@@')[0].replace(/@@URD@@/g, '')
        setUrsDocument(documentString)
        setDoc({
          ...doc,
          quotation: documentString,
        })
      })
      io.on('offer_chunk', (data) => {
        const documentString = data.document.split('@@QUESTIONS@@')[0].replace(/@@URD@@/g, '')
        setUrsDocument(documentString)
        setDoc({
          ...doc,
          offer: documentString,
        })
      })
      io.on('inject_questions', (data) => {
        const questions = data?.questions ?? []
        setDoc({
          ...doc,
          questions,
        })
      })

      io.on('quotation_done', () => {
        loadURS()
      })

      io.on('offer_done', () => {
        loadURS()
      })

      io.on('proposal_done', () => {
        loadURS()
      })
    }
  }, [io])

  useEffect(() => {
    //@ts-ignore
    window.closeCreateModal?.()
  }, [])
  const askAI = async (question: string) => {
    const currentDocument = tiptapEditorRef.current?.getMarkdown()
    const result = await io.emitWithAck('askUrsAgent', {
      ursUUID,
      question,
      token: localStorage.getItem('accessToken'),
      currentDocument,
    })

    const parts = result.split('@@URD@@')
    if (parts.length > 1) {
      setUrsDocument(parts[1] ?? ursDocument)
    }
    return parts[0]
  }

  const onQuestionAnswer = async (answer: string, index: number) => {
    // Track interaction with Questions Picker
    const query = doc.questions[index]
    Mixpanel.track('Questions picker used', {
      question: query.question,
      selected_answer: answer,
      answer_type: query.responses.includes(answer) ? 'suggested' : 'custom', // Indicates if the answer was suggested or custom-defined
      section: query.section,
      question_index: index,
    })
    setDocumentLoading(true)
    try {
      const document = tiptapEditorRef.current?.getMarkdown()
      const newURS = await io.emitWithAck('answerQuestionForDocument', {
        answer,
        index,
        document,
        ursUUID,
        question: query.question,
        token: localStorage.getItem('accessToken'),
      })
      const questions = doc.questions ?? []
      questions[index].answer = answer
      setDoc({
        ...doc,
        questions,
        document: newURS.document,
      })
      setUrsDocument(newURS.document)
      tiptapEditorRef.current?.setMarkdown(newURS.document)
      setDocumentLoading(false)
    } catch (err) {
      console.log('Error here ', err)
    }
  }
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <div className="flex w-full h-screen bg-white">
        {initialized ? (
          <div className="flex w-full h-full gap-2 overflow-hidden">
            {/* Main Content Area */}
            <div className={cn('flex grow h-full justify-center gap-2')}>
              <div
                className={cn(
                  'flex flex-col w-full overflow-y-scroll no-scrollbar items-center transition-all duration-500 ease-in-out',
                )}
              >
                {/* Doc Editor */}
                <div className="flex flex-col w-full items-center">
                  <TipTapEditor
                    ref={tiptapEditorRef}
                    markdown={ursDocument}
                    ursUUID={doc.uuid}
                    isReadOnly={isReadOnly}
                    doc={doc}
                    docDesignSettings={docDesignSettings}
                    onQuestionAnswer={onQuestionAnswer}
                    variant={variant}
                    setDoc={setDoc}
                  />
                </div>

                {/* Doc Feedback Bar */}
                <div className="flex w-full sticky bottom-2 justify-center z-30">
                  {(doc.score === undefined || doc.score === -1) && !isReadOnly && (
                    <FeedbackBar ursUUID={ursUUID as string} />
                  )}
                </div>

                {/* Doc Save Info */}
                {documentLoading && (
                  <div className={'fixed w-full h-full top-0 left-0 z-50'}>
                    <div
                      className={
                        'bg-black/75 backdrop-blur-sm w-full h-full justify-center items-center flex flex-col text-2xl text-white gap-3'
                      }
                    >
                      <div className={'flex flex-row gap-2 justify-center items-center'}>
                        <Spinner size={'lg'} color="secondary" />
                        <span>Adding new information to document…</span>
                      </div>
                      <span className={'text-white text-sm opacity-60 text-center'}>
                        It can take up to 30 seconds.
                        <br />
                        Please wait and keep this browser tab open.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Doc Sidebar */}
            {!isReadOnly && (
              <div className="flex-none w-[130px]">
                <DocSidebar
                  showAIAssistant={setShowAIAssistant}
                  isPreview={setIsPreview}
                  downloadPdf={downloadPdf}
                  editor={editorInstance}
                  doc={doc}
                  variant={variant}
                  setLoading={setDocumentLoading}
                  askAI={askAI}
                  docDesignSettings={docDesignSettings}
                  setDocDesignSettings={(callback) => {
                    setDocDesignSettings(callback)
                    io.emitWithAck('update_design', {
                      designSetting: callback(docDesignSettings),
                      token: localStorage.getItem('accessToken'),
                      documentUUID: doc.uuid,
                    })
                  }}
                  onResolveComment={async (selectedComment: any) => {
                    if (selectedComment) {
                      tiptapEditorRef?.current?.unsetComment(selectedComment.uuid)
                      const comment = await io.emitWithAck('resolve_comment', {
                        token: localStorage.getItem('accessToken'),
                        documentUUID: doc.uuid,
                        commentUUID: selectedComment.uuid,
                      })
                      try {
                        const documentComments = [...doc.comments]
                        const index = documentComments.findIndex((item) => item.uuid === selectedComment.uuid)
                        documentComments[index] = {
                          ...documentComments[index],
                          isResolved: true,
                        }
                        setDoc((prev) => ({
                          ...prev,
                          comments: documentComments,
                        }))
                      } catch (e) {}
                    }
                  }}
                />
              </div>
            )}

            {isPreview && (
              <div className={'flex absolute top-0 left-0 w-full h-full z-50 bg-zinc-800'}>
                <div className="flex w-full h-full">
                  <DocumentPreview url={`${baseURL}/urs/preview/${doc.uuid}`} close={() => setIsPreview(false)} />
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="flex flex-1 flex-col justify-center content-center items-center">
              <div className={'w-full h-full justify-center items-center flex gap-3'}>
                <Spinner size={'lg'} color="secondary" />
                <span className={'text-black text-xl'}>Retrieving project details…</span>
              </div>
            </div>
            <div></div>
          </>
        )}
      </div>
    </>
  )
}

interface ITranscription {
  text: string
  source: string
  isNew: boolean
}

interface IInsight {
  type: string
  content: string
}

interface IImplementation {
  title: string
  description: string
}
