import { Button, Divider, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'
import React, { useMemo, useState } from 'react'
import api from 'src/lib/api'
import { useWorkspaceContextData } from 'src/context/workspace'
import { useNavigate } from 'react-router-dom'
import RSelect from 'react-select/creatable'

export default function AssingCreateClientModal({
  proposal,
  isOpenAssignClient,
  onOpenAssignClient,
  onAssignToClient,
}: {
  isOpenAssignClient: boolean
  onOpenAssignClient: (isOpen: boolean) => void
  proposal: any
  onAssignToClient: (documentUUID: string, clientUUID: string, tempClient?: any) => Promise<void>
}) {
  const [creatingClient, setCreatingClient] = useState<boolean>(false)
  const [name, setName] = useState('')
  const [agencyName, setAgencyName] = useState<string>('')
  const [role, setAgencyRole] = useState<string>('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const { activeWorkspace, addClient } = useWorkspaceContextData()
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false)
  const [clientUUID, setClientUUID] = useState<string>('')
  const navigate = useNavigate()
  const { clientsFullList } = useWorkspaceContextData()

  const createClient = async () => {
    if (name.length === 0 || creatingClient) return
    setCreatingClient(true)
    const res = await api.post('/clients/', {
      name,
      role,
      phone,
      email,
      agencyName,
      workspaceUUID: activeWorkspace,
    })
    addClient?.(res.data)
    setCreatingClient(false)
    onOpenAssignClient(false)
    setName('')
    setAgencyRole('')
    setAgencyName('')
    setPhone('')
    setEmail('')
    addClient?.(res.data)
    onAssignToClient(proposal.uuid, res.data?.uuid, res.data)
    onOpenAssignClient(false)
  }

  const assignClient = () => {
    onAssignToClient(proposal.uuid, clientUUID)
    onOpenAssignClient(false)
  }
  const options = useMemo(
    () =>
      clientsFullList.map((client) => ({
        value: client.uuid,
        label: client.name,
      })),
    [clientsFullList],
  )
  return (
    <Modal
      size="3xl"
      isOpen={isOpenAssignClient}
      onOpenChange={onOpenAssignClient}
      className="z-[1000] select-dialog"
      isDismissable={false}
      backdrop="opaque"
    >
      <div
        onClick={(e) => {
          if (e.target.getAttribute('data-slot') === 'wrapper') {
            onOpenAssignClient(false)
          }
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1"></ModalHeader>
              <ModalBody>
                <div className="flex flex-col grow gap-7">
                  <div className="flex flex-col items-center">
                    <p className="text-2xl font-semibold">
                      {showCreateForm ? 'Add new client' : `Assign document to client`}
                    </p>
                    <p className="text-base text-center">
                      {showCreateForm
                        ? 'Add new client to this workspace'
                        : `Assign the project ${proposal.title} to client`}
                    </p>
                  </div>
                  {showCreateForm ? (
                    <div className="flex flex-col gap-3 items-center">
                      <Input
                        isRequired
                        type="text"
                        label="Client name"
                        placeholder="e.g. John Doe"
                        className="w-full"
                        defaultValue={name}
                        onChange={(e) => setName(e.target.value)}
                        autoFocus={true}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' && name?.length > 0) {
                            createClient()
                          }
                        }}
                      />
                      <Divider orientation="horizontal" />
                      <div className={'flex w-full flex-row gap-2'}>
                        <Input
                          type="text"
                          label="Agency Name"
                          placeholder="e.g. ACM Inc"
                          className="flex flex-1"
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' && name?.length > 0) {
                              createClient()
                            }
                          }}
                          onChange={(e) => setAgencyName(e.target.value)}
                        />
                        <Input
                          type="text"
                          label="Client Role"
                          placeholder="e.g. CEO"
                          className="flex flex-1"
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' && name?.length > 0) {
                              createClient()
                            }
                          }}
                          onChange={(e) => setAgencyRole(e.target.value)}
                        />
                      </div>
                      <div className={'flex w-full flex-row gap-2'}>
                        <Input
                          type="text"
                          label="Client E-mail"
                          placeholder="e.g. john.doe@acme.io"
                          className="flex flex-1"
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' && name?.length > 0) {
                              createClient()
                            }
                          }}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Input
                          type="text"
                          label="Client Phone"
                          placeholder="e.g. +1(234) 5678-9000"
                          className="flex flex-1"
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' && name?.length > 0) {
                              createClient()
                            }
                          }}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className={' relative flex flex-col gap-3 items-center'}>
                      <RSelect
                        className={'flex flex-1 w-full'}
                        onChange={(option) => {
                          setClientUUID(option.value)
                        }}
                        placeholder={`type to create new client or select from list`}
                        formatCreateLabel={(inputValue: string) => `Create new client: ${inputValue}`}
                        onCreateOption={(name) => {
                          setName(name)
                          setShowCreateForm(true)
                        }}
                        options={options}
                        styles={{
                          control: (base) => ({
                            ...base,
                            width: '100%', // Ensure the control spans the full width
                            minWidth: '100%',
                          }),
                          menu: (base) => ({
                            ...base,
                            width: '100%', // Dropdown menu spans full width
                            minWidth: '100%',
                          }),
                          menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                        }}
                        menuPortalTarget={window.document.querySelector('body')}
                      />
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  onPress={showCreateForm ? createClient : assignClient}
                  isDisabled={(showCreateForm ? name.length === 0 : clientUUID.length === 0) || creatingClient}
                >
                  {showCreateForm ? 'Create & Assign Document' : 'Assign Document'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  )
}
