import React, { ReactNode, useEffect, useState, useRef } from 'react'
import { useMediaQuery } from 'usehooks-ts'
import {
  cn,
  Button,
  Card,
  CardBody,
  ScrollShadow,
  Spacer,
  useDisclosure,
  Image,
  Select,
  SelectSection,
  SelectItem,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Progress,
  Chip,
  Tooltip,
  Spinner,
} from '@nextui-org/react'

import { Icon } from '@iconify/react'

import Sidebar, { SidebarItem, SidebarItemType } from '../../components/sidebar'

import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import Mixpanel from 'src/lib/mixpanel'
import api from 'src/lib/api'
import { useWorkspaceContextData } from 'src/context/workspace'
import { useSubscriptionContext } from 'src/context/subscription'
import CreateNewClientModal from 'src/pages/layout/CreateNewClientModal'
import CreateNewWorkspace from 'src/pages/layout/CreateNewWorkspace'
import EditClientModal from './EditClientModal'
import DeleteClientModal from 'src/pages/layout/DeleteClientModal'
import DuplicateClientModal from 'src/pages/layout/DuplicateClientModal'
import PersonalizePowerdocsAI from 'src/pages/layout/PersonalizePowerdocsAI'
import CreateUrdWindow from 'src/pages/ursList/CreateUrdWindow'
import { emitter } from 'src/lib/EventEmitter'
import { SubscribeLimitDocument } from 'src/components/modals/subscribe-limit-document'
import toast from 'react-hot-toast'

interface IProps {
  leftNode?: ReactNode
}

export default function Layout(props: IProps) {
  const [sending, setSending] = useState<boolean>(false)
  const resendEmail = async () => {
    if (!sending) {
      setSending(true)
      const res = await api.post('/auth/resend')
      setTimeout(() => {
        setSending(false)
        toast.success('Verification email has been sent successfully')
      }, 500)
    }
  }
  const isCompact = useMediaQuery('(max-width: 768px)')
  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onOpenChange: onCreateOpenChange } = useDisclosure()
  const { isOpen: openedNewClient, onOpen: onOpenNewClient, onOpenChange: onOpenNewClientChange } = useDisclosure()
  const { isOpen: openedEditClient, onOpen: onOpenEditClient, onOpenChange: onOpenEditClientChange } = useDisclosure()
  const { isOpen: openedNewProject, onOpen: openNewProject, onOpenChange: openingNewProject } = useDisclosure()
  const { isOpen: openedMergeClient, onOpen: openMergeCLient, onOpenChange: onOpenMergeClient } = useDisclosure()
  const {
    isOpen: isOpenPersonalizePowerdocsAI,
    onOpen: onOpenPersonalizePowerdocsAI,
    onOpenChange: onOpenChangePersonalizePowerdocsAI,
  } = useDisclosure()
  const {
    isOpen: openedDeleteClient,
    onOpen: openDeleteClient,
    onOpenChange: onOpenDeleteClientChange,
  } = useDisclosure()
  const {
    isOpen: openedDuplicateClient,
    onOpen: openDuplicateClient,
    onOpenChange: onOpenDuplicateClientChange,
  } = useDisclosure()
  const { activeWorkspace, setActiveWorkspace } = useWorkspaceContextData()
  const navigate = useNavigate()
  const location = useLocation()
  const selectRef = useRef<Select | null>(null)
  const [workspaces, setWorkspaces] = useState([
    {
      value: '0',
      label: 'Available workspaces',
      items: [],
    },
  ])

  const [sideBarItems, setSideBarItems] = useState<SidebarItem[]>([
    {
      key: 'home',
      link: '/',
      icon: 'solar:home-2-linear',
      title: 'Home',
    },
    {
      key: 'submissions',
      link: '/submissions',
      icon: 'lucide:files',
      title: 'Document hub',
    },
    {
      key: 'clients',
      title: 'Active clients',
      items: [],
      type: SidebarItemType.Nest,
      isLoading: true,
    },
    {
      key: 'organziation',
      title: 'Organization',
      items: [
        {
          key: 'team',
          link: '/team',
          icon: 'solar:users-group-two-rounded-outline',
          title: 'Team',
          endContent: (
            <Chip size="sm" variant="flat">
              New
            </Chip>
          ),
        },
        {
          key: 'audit',
          link: '/audit',
          icon: 'solar:file-text-outline',
          title: 'Audit logs',
          endContent: (
            <Chip size="sm" variant="flat">
              Soon
            </Chip>
          ),
        },
        {
          key: 'settings',
          link: '/settings',
          icon: 'solar:settings-outline',
          title: 'Settings',
        },
      ],
    },
  ])
  const [clientToEdit, setClientToEdit] = useState<any>({})
  const [user, setUser] = useState<any>(null)
  const loadUser = async () => {
    const userString = localStorage.getItem('user')
    if (userString) {
      try {
        setUser(JSON.parse(userString))
      } catch (err) {
        console.log('error parsing user')
      }
    }
    const res = await api.get('/agencies/profile')
    localStorage.setItem('user', JSON.stringify(res.data))
    setUser(res.data)
  }
  const isPublicPage =
    location.pathname.startsWith('/login') ||
    location.pathname.startsWith('/signup') ||
    location.pathname.startsWith('/document') ||
    location.pathname.startsWith('/account/reset') ||
    location.pathname.startsWith('/verify')
  const { getUserSubscription, currentLimitations } = useSubscriptionContext()
  useEffect(() => {
    if (!isPublicPage && !user) {
      loadUser()
      getUserSubscription?.()
    }
  }, [isPublicPage])

  useEffect(() => {
    if (user) {
      const newWorkspaces = (user.workspaces || []).map((workspace: any) => ({
        value: workspace.uuid,
        key: workspace.uuid,
        label: workspace.name ?? workspace.uuid,
      }))
      setWorkspaces([
        {
          value: '0',
          label: 'Available workspaces',
          items: newWorkspaces,
        },
      ])
      if (!activeWorkspace || activeWorkspace === 'null')
        setActiveWorkspace?.(user.defaultWorkspace ?? newWorkspaces?.[0]?.value ?? '')
    }
  }, [user])
  useEffect(() => {
    if (user) getUserSubscription?.()
    if (user && !user.customizedPowerDocs) onOpenPersonalizePowerdocsAI()
  }, [user])

  useEffect(() => {
    if (navigate) {
      window.navigate = navigate
    }
  }, [navigate])

  useEffect(() => {
    const listnerForUpdateSubmission = (value: any) => {
      if (user) {
        const newWorkspaces = [...user.workspaces]
        const worksapceIndex = newWorkspaces.findIndex((item) => item.uuid === value.uuid)
        newWorkspaces[worksapceIndex] = {
          ...newWorkspaces[worksapceIndex],
          submissionCount: (newWorkspaces[worksapceIndex]?.submissionCount ?? 0) + value.step,
        }
        setUser({
          ...user,
          workspaces: newWorkspaces,
        })
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...user,
            workspaces: newWorkspaces,
          }),
        )
      }
    }
    emitter.on('updateSubmissionCount', listnerForUpdateSubmission)
    return () => {
      emitter.off('updateSubmissionCount', listnerForUpdateSubmission)
    }
  }, [user])
  const logout = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('email')

    // localStorage.clear()
    const keysToKeep = new Set([
      "_grecaptcha", // Google reCAPTCHA
      "iconify-count", // Iconify optimization
      "iconify-version",
      "iconify0"
    ]);
    
    // Dynamically detect and preserve all Mixpanel (`mp_*`), Crisp chat (`crisp-client/session/*`), etc. keys
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("mp_") || key.startsWith("crisp-client/session/") || key.startsWith("AMP_unsent_") || key.startsWith("__mpq_") || keysToKeep.has(key)) {
          return; // Keep these keys
      }
      localStorage.removeItem(key); // Remove everything else
    });
    
    Mixpanel.logout()
    navigate('/login')
  }
  const { clientsList, clientLoading } = useWorkspaceContextData()
  const [selectedClient, setSelectedClient] = useState<string>('')
  const {
    isOpen: isSubscriptionModalOpen,
    onOpen: openSubscriptionModal,
    onClose: closeSubscriptionModal,
  } = useDisclosure()

  const renderEndContent = (item: any) => (
    <div className={'flex justify-center gap-2'}>
      <Dropdown
        classNames={{
          content: 'rounded-xl border border-1 border-success-500 min-w-[150p]',
        }}
      >
        <DropdownTrigger>
          <Button
            variant={'light'}
            className={'p-0 m-0 data-[hover=true]:bg-transparent'}
            onPress={() => {
              setSelectedClient(item.uuid)
            }}
          >
            <Icon icon={'lucide:more-horizontal'} />
          </Button>
        </DropdownTrigger>
        <DropdownMenu aria-label="client Actions">
          <DropdownItem
            key="edit"
            onPress={() => {
              setClientToEdit(item)
              onOpenMergeClient(true)
            }}
          >
            <span className="flex flex-row w-full font-bold items-center gap-2">
              <Icon icon={'lucide:merge'} className={'text-success-500'} />
              <span className={'text-md font-bold'}>Merge Client</span>
            </span>
          </DropdownItem>
          <DropdownItem
            key="edit"
            onPress={() => {
              setClientToEdit(item)
              onOpenEditClient()
            }}
          >
            <span className="flex flex-row w-full font-bold items-center gap-2">
              <Icon icon={'lucide:edit-3'} className={'text-success-500'} />
              <span className={'text-md font-bold'}>Edit</span>
            </span>
          </DropdownItem>
          <DropdownItem
            key="duplicate"
            showDivider
            onPress={() => {
              setClientToEdit(item)
              onOpenDuplicateClientChange()
            }}
          >
            <span className="flex flex-row w-full font-bold items-center gap-2">
              <Icon icon={'lucide:book-copy'} className={'text-success-500'} />
              <span className={'text-md font-bold'}>Duplicate</span>
            </span>
          </DropdownItem>
          <DropdownItem
            key="delete"
            className="text-danger"
            color="danger"
            onPress={() => {
              setClientToEdit(item)
              onOpenDeleteClientChange()
            }}
          >
            <span className="flex flex-row w-full font-bold items-center gap-2">
              <Icon icon={'lucide:trash-2'} />
              <span className={'text-md font-bold'}>Delete</span>
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
  useEffect(() => {
    if (clientsList !== undefined && user && activeWorkspace) {
      const workspace = user.workspaces?.filter((item: any) => item.uuid === activeWorkspace).pop()
      setSideBarItems([
        {
          key: 'home',
          link: '/',
          icon: 'solar:home-2-linear',
          title: 'Home',
        },
        {
          key: 'submissions',
          link: '/submissions',
          icon: 'lucide:files',
          title: (
            <div className="flex flex-col">
              <div className="flex items-center gap-1">Document hub</div>
              <div className="text-tiny text-default-500">Projects</div>
            </div>
          ),
          endContent:
            workspace?.submissionCount > 0 ? (
              <Chip color="secondary">{workspace?.submissionCount > 99 ? '99+' : workspace?.submissionCount}</Chip>
            ) : undefined,
        },
        {
          key: 'clients',
          title: (
            <span className="flex items-center gap-1">
              {/* Active clients
              <Tooltip content="Active clients with at least one ‘accepted’ project" color="foreground">
                <Icon icon="lucide:help-circle" width="14" className="inline-block align-middle" />
              </Tooltip> */}
              Clients
            </span>
          ),
          items: (clientsList || []).map((client) => ({
            key: client.uuid,
            link: '/client/' + client.uuid,
            icon: 'lucide:user',
            title: client.name,
            endContent: renderEndContent(client),
          })),
          isEmpty: (clientsList ?? []).length === 0,
        },
        {
          key: 'organziation',
          title: 'Organization',
          items: [
            {
              key: 'team',
              link: '/team',
              icon: 'solar:users-group-two-rounded-outline',
              title: 'Team',
              endContent: (
                <Chip size="sm" variant="flat">
                  New
                </Chip>
              ),
            },
            {
              key: 'audit',
              link: '/audit',
              icon: 'solar:file-text-outline',
              title: 'Audit logs',
              endContent: (
                <Chip size="sm" variant="flat">
                  Soon
                </Chip>
              ),
            },
            {
              key: 'settings',
              link: '/settings',
              icon: 'solar:settings-outline',
              title: 'Settings',
            },
          ],
        },
      ])
    }
  }, [clientsList, location.pathname, activeWorkspace, user])

  const {
    isOpen: isCreateModalOpen,
    onOpenChange: onOpenCreateModalChange,
    onClose: onCloseCreateModal,
  } = useDisclosure()

  useEffect(() => {
    // Check if the current path is `/create`
    if (location.pathname === '/create' && currentLimitations) {
      if (currentLimitations && !currentLimitations?.canAddDocument) {
        openSubscriptionModal()
      } else {
        onOpenCreateModalChange()
      }
    }
  }, [location.pathname, currentLimitations])

  // Open 'Customize Powerdocs AI' modal automatically as often till user modifies it
  // useEffect(() => {
  //   onOpenPersonalizePowerdocsAI();
  // }, [])

  return (
    <>
      <div className="flex h-full">
        {/* 'No mobile support' overlay */}
        {!isPublicPage && (
          <div
            className="flex items-center fixed bottom-0 left-0 right-0 w-full px-2 pb-2 md:hidden"
            style={{ zIndex: 100 }}
          >
            <Card isBlurred className="border-small border-default-200 bg-default-50">
              <CardBody className="flex flex-row h-full items-start gap-3 p-4">
                <div
                  className={cn(
                    'item-center flex rounded-medium border p-2 bg-default-50 border-default-100 text-default-500',
                  )}
                >
                  <Icon icon="lucide:badge-alert" width={24} />
                </div>
                <div className="flex flex-col">
                  <p className="text-medium">Powerdocs works best on desktop</p>
                  <p className="text-small text-default-500">Switch to your computer to unlock the full experience.</p>
                </div>
              </CardBody>
            </Card>
          </div>
        )}
        {/* {!['/', '/signup', '/login', '/home'].includes(pathname) && !pathname.startsWith('/urs') && (
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex items-center justify-center z-50 md:hidden">
            <div className="text-center text-white p-4">
              <Icon icon="solar:monitor-smartphone-outline" className="mx-auto mb-4" width={48} />
              <h1 className="text-2xl font-bold pb-2">No mobile support yet</h1>
              <p className="text-lg">Powerdocs is optimized for desktop,</p>
              <p className="text-lg">please switch to your computer.</p>
            </div>
          </div>
        )} */}
        {/* END 'No mobile support' overlay */}

        {localStorage.getItem('email') !== null && !isPublicPage && (
          <div
            className={cn(
              'relative flex h-full min-h-screen w-[18rem] min-w-[18rem] max-w-[18rem] flex-col !border-r-small border-divider p-6 transition-width',
              {
                'w-16 min-w-16 max-w-16 items-center px-2 py-6': isCompact,
              },
            )}
          >
            <div className="flex items-center gap-2 px-2">
              <div onClick={() => navigate('/')} className={'cursor-pointer'}>
                <Image
                  src="/img/powerdocs-logo.png"
                  alt="Powerdocs Logo"
                  width={140}
                  radius="none"
                  className={cn({ hidden: isCompact })}
                />
                <Image
                  src="/img/powerdocs-logo-signet.png"
                  alt="Powerdocs Logo Icon"
                  width={140}
                  radius="none"
                  className={cn({ hidden: !isCompact })}
                />
              </div>
            </div>

            <Spacer y={4} />

            {/* Sidebar header */}
            <div className="flex flex-col gap-2 w-full">
              {/* Workspace switcher */}
              <div className="flex flex-col gap-y-2">
                <Select
                  isDisabled={true}
                  disabled={true}
                  ref={selectRef}
                  disableSelectorIconRotation
                  aria-label="Select workspace"
                  className="px-0 hidden"
                  classNames={{
                    trigger:
                      'min-h-14 py-6 bg-transparent border-small border-default-200 dark:border-default-100 data-[hover=true]:border-default-500 dark:data-[hover=true]:border-default-200 data-[hover=true]:bg-transparent',
                  }}
                  popoverProps={{
                    style: { minWidth: '200px' },
                  }}
                  onChange={(e) => {
                    if (e.target.value && e.target.value !== activeWorkspace.toString()) {
                      // Trigger gradient shine effect
                      const shineElement = document.createElement('div')
                      shineElement.classList.add('gradient-shine')
                      document.body.appendChild(shineElement)

                      // Remove the shine effect after the animation ends
                      setTimeout(() => {
                        document.body.removeChild(shineElement)
                      }, 1000)

                      // Track the workspace switch
                      Mixpanel.track('Workspace switched', {
                        previous_workspace: activeWorkspace,
                        new_workspace: e.target.value,
                      })
                      console.log(e)
                      setActiveWorkspace?.(e.target.value)
                      navigate('/')
                    }
                  }}
                  items={workspaces}
                  selectedKeys={[activeWorkspace]}
                  listboxProps={{
                    className: 'min-w-[150px]',
                    // classNames: {list: "max-h-[200px] overflow-y-auto scrollbar-hide"},
                    topContent: (
                      <Button
                        className="text-center mb-2"
                        size="md"
                        color="secondary"
                        startContent={<Icon icon="lucide:circle-plus" />}
                        onPress={() => {
                          console.log(selectRef.current?.hide, selectRef.current?.close, selectRef.current?.click())
                          // Track the button click for creating a new workspace
                          Mixpanel.track('Workspace creation intent')

                          // Function to handle workspace creation modal
                          onCreateOpenChange()
                        }}
                      >
                        Create workspace
                      </Button>
                    ),
                  }}
                  placeholder="Select workspace"
                  renderValue={(items) => {
                    return items.map((item) => (
                      <div key={item.key} className={cn('ml-1 flex flex-col gap-y-0.5', { hidden: isCompact })}>
                        <span className="text-tiny leading-4">{item.data?.label}</span>
                        <span className="text-tiny text-default-400">Workspace</span>
                      </div>
                    ))
                  }}
                  selectorIcon={<></>}
                  startContent={
                    <div
                      className={cn('relative h-10 w-10 flex-none rounded-full border-small border-default-300', {
                        hidden: isCompact,
                      })}
                    >
                      <Icon className="ml-2 mt-2 text-default-500" icon="solar:users-group-rounded-linear" width={24} />
                    </div>
                  }
                >
                  {(section) => (
                    <SelectSection
                      key={section.value}
                      hideSelectedIcon
                      aria-label={section.label}
                      items={section.items}
                      title={section.label}
                    >
                      {/* @ts-ignore */}
                      {(item) => (
                        <SelectItem
                          key={item.value}
                          aria-label={item.label}
                          textValue={item.value}
                          isSelected={item.value === activeWorkspace}
                        >
                          <div className="flex flex-row items-center justify-between gap-1">
                            <span>{item.label}</span>
                            <div className="flex h-6 w-6 items-center justify-center rounded-full border-small border-default-300">
                              <Icon className="text-default-500" icon="solar:users-group-rounded-linear" width={12} />
                            </div>
                          </div>
                        </SelectItem>
                      )}
                    </SelectSection>
                  )}
                </Select>
              </div>
              {/* END Workspace switcher */}

              {/* Create new document */}
              <Button
                fullWidth
                className="justify-center"
                variant="solid"
                color="secondary"
                isDisabled={!user?.isActive}
                onPress={() => {
                  // Track button click
                  Mixpanel.track('Document creation intent', {
                    page_name: window.location.pathname,
                    location: 'Sidebar',
                  })
                  if (currentLimitations && !currentLimitations?.canAddDocument) {
                    openSubscriptionModal()
                  } else {
                    onOpenCreateModalChange()
                  }
                }}
                startContent={<Icon icon="lucide:plus" width={20} className="text-default-50" />}
              >
                <span className={cn({ hidden: isCompact })}>Create new document</span>
              </Button>
              {user && !user?.isActive && (
                <>
                  <div>
                    <span
                      className={
                        'text-warning-500 text-underline flex flex-row items-center justify-center gap-1 text-sm'
                      }
                    >
                      <Icon icon={'lucide:octagon-alert'} size={24} />
                      Please verify your email address
                    </span>
                    <span
                      className={cn(
                        'text-underline flex flex-row items-center justify-center gap-1 text-sm underline cursor-pointer mt-0',
                        sending ? 'text-warning-200 cursor-progress' : 'text-warning-500',
                      )}
                      onClick={resendEmail}
                    >
                      Resend email now
                      {sending && <Spinner color={'warning'} size={'sm'} />}
                    </span>
                  </div>
                </>
              )}
              {/* END Create new document */}

              {/* Document counter */}
              {!currentLimitations?.isPro && (
                <div
                  className={cn('flex flex-col pb-1', { hidden: isCompact })}
                  onClick={() => {
                    // Track button click in Mixpanel
                    Mixpanel.track('Upgrade button clicked', {
                      button_name: 'Upgrade now',
                      page_name: window.location.pathname,
                      location: 'Sidebar',
                    })

                    // Navigate to billing settings
                    navigate('/settings/billing')
                  }}
                >
                  <Card
                    className="cursor-pointer shadow-none border-1"
                    onPress={() => {
                      // Track button click in Mixpanel
                      Mixpanel.track('Upgrade button clicked', {
                        button_name: 'Upgrade now',
                        page_name: window.location.pathname,
                        location: 'Sidebar',
                      })

                      // Navigate to billing settings
                      navigate('/settings/billing')
                    }}
                  >
                    <CardBody>
                      <span className={'text-sm text-secondary text-center font-medium'}>
                        {Math.max(3 - (currentLimitations?.countDocument ?? 0), 0)}/3 documents left this month
                      </span>
                      <Progress
                        color="secondary"
                        value={100 - Math.min(100, ((currentLimitations?.countDocument ?? 0) / 3) * 100)}
                        className="max-w-md"
                      />
                      <div className="flex w-full justify-center">
                        <span className={'text-sm text-secondary text-center'}>Upgrade now</span>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              )}
              {/* END Document counter */}

              {/* Search */}
              {/*<Input placeholder={'Search...'} className={'rounded-md mt-2'} />*/}
              {/* END Search */}
              <CreateUrdWindow
                isOpen={isCreateModalOpen}
                onClose={onCloseCreateModal}
                projectUUID={undefined}
                showSubscriptionError={() => {
                  setTimeout(() => openSubscriptionModal(), 1000)
                }}
              />
              <SubscribeLimitDocument isOpen={isSubscriptionModalOpen} onClose={closeSubscriptionModal} />
            </div>
            {/* END Sidebar header */}

            <Spacer y={4} />

            {/* Sidebar */}
            <ScrollShadow className="-mr-6 h-full max-h-full py-8 pr-6 pt-0 mt-0" hideScrollBar>
              <Sidebar items={sideBarItems} defaultSelectedKey="home" isCompact={isCompact} isLoading={clientLoading} />
            </ScrollShadow>
            {/* END Sidebar */}

            {/* Sidebar ad */}
            <div className="mt-auto flex flex-col">
              <div
                id="chrome-extension-ad"
                onClick={() => {
                  Mixpanel.track('Chrome Extension Ad Clicked', {
                    page_name: window.location.pathname,
                    location: 'Sidebar',
                  })
                  window.open('https://powerdocs.so/browser-extension', '_blank')
                }}
                style={{ position: 'relative' }}
              >
                <Card className={cn('flex w-full cursor-pointer', { hidden: isCompact })}>
                  <CardBody className="flex items-start relative">
                    <div className="flex flex-col">
                      <span className="text-tiny font-semibold text-secondary">NEW</span>
                      <div className="text-lg font-semibold">Chrome extension</div>
                      <div className="text-sm">
                        Create proposals instantly from any website – <span className="underline">try it now</span>
                      </div>
                    </div>

                    {/* Close Button */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation() // Prevents clicking from triggering the window.open
                        const adElement = document.getElementById('chrome-extension-ad')
                        if (adElement) {
                          adElement.style.display = 'none' // Hide instead of removing
                        }
                      }}
                      className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-all"
                      style={{
                        background: 'transparent',
                        border: 'none',
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    >
                      <Icon icon="lucide:x" width={15} />
                    </button>
                  </CardBody>
                </Card>
              </div>
            </div>
            {/* END Sidebar ad*/}

            <div className="mt-auto flex flex-col">
              <div className="flex">
                <Tooltip content="Help & Support" color="foreground">
                  <Button
                    isIconOnly
                    className="text-default-500 data-[hover=true]:text-foreground"
                    variant="light"
                    onPress={() => {
                      // Track button click
                      Mixpanel.track('Help & Support button clicked', {
                        button_name: 'Help & support',
                        page_name: window.location.pathname,
                        location: 'Sidebar',
                      })

                      // Open Help & Support page
                      window.open('https://powerdocs.so/help')
                    }}
                  >
                    <Icon icon="lucide:heart-handshake" width={24} />
                  </Button>
                </Tooltip>
                <Tooltip content="Give feedback" color="foreground">
                  <Button
                    isIconOnly
                    className="text-default-500 data-[hover=true]:text-foreground"
                    variant="light"
                    onPress={() => {
                      // Track button click
                      Mixpanel.track('Feedback button clicked', {
                        button_name: 'Give feedback',
                        page_name: window.location.pathname,
                        location: 'Sidebar',
                      })

                      // Open feedback email
                      window.open("mailto:feedback@powerdocs.so?subject=I'd like to give feedback or report a bug")
                    }}
                  >
                    <Icon icon="lucide:message-square-heart" width={24} />
                  </Button>
                </Tooltip>
                <Tooltip content="Log out" color="foreground">
                  <Button
                    isIconOnly
                    className="text-default-500 data-[hover=true]:text-foreground"
                    variant="light"
                    onPress={() => {
                      // Track button click
                      Mixpanel.track('Log out button clicked', {
                        button_name: 'Log out',
                        page_name: window.location.pathname,
                      })

                      // Log out
                      logout()
                    }}
                  >
                    <Icon icon="lucide:log-out" width={24} />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        )}

        {/* --- Content --- */}
        {/* --------------- */}
        <div className="flex flex-row w-full">
          {/* <div><Header /></div> */}
          <Outlet />
        </div>

        {/* --- Modals --- */}
        {/* -------------- */}
        <CreateNewClientModal openedNewClient={openedNewClient} onOpenNewClientChange={onOpenNewClientChange} />
        <EditClientModal
          clientToEdit={clientToEdit}
          openedEditClient={openedEditClient}
          onOpenEditClientChange={onOpenEditClientChange}
        />
        <CreateNewWorkspace
          isCreateOpen={isCreateOpen}
          onCreateOpenChange={(isOpen?: boolean) => {
            onCreateOpenChange()
          }}
          loadUser={loadUser}
        />
        <DeleteClientModal
          openedDeleteClient={openedDeleteClient}
          onOpenDeleteClientChange={onOpenDeleteClientChange}
          clientToEdit={clientToEdit}
        />
        <DuplicateClientModal
          openedDuplicateClient={openedDuplicateClient}
          onOpenDuplicateClientChange={onOpenDuplicateClientChange}
          clientToEdit={clientToEdit}
        />
        <PersonalizePowerdocsAI
          isOpen={isOpenPersonalizePowerdocsAI}
          onOpenChange={onOpenChangePersonalizePowerdocsAI}
        />
      </div>
    </>
  )
}
