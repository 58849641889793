'use client'

import * as React from 'react'
import { useEffect, useState } from 'react'
import api from 'src/lib/api'
import { Button, Input, Spacer } from '@nextui-org/react'

import { cn } from './cn'
import toast from 'react-hot-toast'

interface AccountSettingCardProps {
  className?: string
}
const AccountSetting = React.forwardRef<HTMLDivElement, AccountSettingCardProps>(({ className, ...props }, ref) => {
  const [user, setUser] = useState<any>()
  const [firstname, setFirstName] = useState<string>('')
  const [lastname, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [agencyName, setAgencyName] = useState<string>('')
  const [agencyRole, setAgencyRole] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const loadUser = () => {
    const userString = localStorage.getItem('user')
    if (userString) {
      setUser(JSON.parse(userString))
    }
    api
      .get('/agencies/profile')
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setUser(res.data)
      })
      .catch((e) => {
        console.log('Error loading current profile')
      })
  }
  useEffect(() => {
    loadUser()
  }, [])
  useEffect(() => {
    if (user) {
      setFirstName(user.firstname ?? '')
      setLastName(user.lastname ?? '')
      setEmail(user.email ?? '')
      setPhone(user.phone ?? '')
      setAgencyName(user.agencyName ?? '')
      setAgencyRole(user.agencyRole ?? '')
    }
  }, [user])
  const updateAccount = async () => {
    if (password !== confirmPassword) {
      toast.error('Verify your password', {
        style: {
          borderRadius: '10px',
          background: '#96172230',
          color: '#1c1c1c',
        },
      })
      return
    }
    api
      .post('/agencies/profile', {
        firstname,
        email,
        lastname,
        phone,
        agencyName,
        agencyRole,
        password: password ? password : undefined,
      })
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setUser(res.data)
        toast.success('Account details has been updated!', {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        })
      })
      .catch((e) => {
        console.log('Error loading current profile')
      })
  }

  return (
    <>
      <div className="flex w-full pt-2 pb-6 px-2 pl-0">
        <header className="flex w-full justify-between">
          <div className="flex flex-col">
            <h1 className="text-xl font-bold text-default-900 lg:text-3xl">Profile</h1>
            <p className="text-small text-default-700 lg:text-medium">
              Manage your profile details, account settings, and security preferences.
            </p>
          </div>
        </header>
      </div>
      <div ref={ref} className={cn('p-2', className)} {...props}>
        {/* Full name */}
        <div>
          <p className="text-base font-medium text-default-700">Your details</p>
          <p className="mt-1 text-sm font-normal text-default-400">
            Name to be used for emails and document cover page.
          </p>
          <div className="flex flex-wrap items-center gap-3 sm:flex-nowrap">
            <Input
              className="mt-2"
              label="First name"
              type="text"
              placeholder="e.g. Kate"
              value={firstname ?? ''}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Input
              className="mt-2"
              label="Last name"
              type="text"
              placeholder="e.g. Moore"
              value={lastname ?? ''}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <Input
            className="mt-3"
            label="Work email"
            type="email"
            placeholder="e.g kate.moore@acme.com"
            value={email ?? ''}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <Spacer y={8} />
        {/* Password */}
        <div>
          <p className="text-base font-medium text-default-700">Password</p>
          <p className="mt-1 text-sm font-normal text-default-400">Change your password if needed.</p>
          <div className="flex flex-wrap items-center gap-3 sm:flex-nowrap">
            <Input
              className="mt-2"
              label="Choose new password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              className="mt-2"
              label="Confirm new password"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>
        <Spacer y={2} />
        <Button
          className="mt-5"
          color="secondary"
          size="md"
          radius="md"
          onClick={updateAccount}
          isDisabled={password !== confirmPassword}
        >
          Update Account
        </Button>
      </div>
    </>
  )
})

AccountSetting.displayName = 'AccountSetting'

export default AccountSetting
