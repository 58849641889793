import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { BreadcrumbItem, Breadcrumbs, Tab, Tabs, Spacer } from '@nextui-org/react'
import api from 'src/lib/api'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import AccountSetting from './account-setting'
import BillingSetting from './billing-setting'
import CompanyDetails from 'src/pages/settings/company-details'
import { useWorkspaceContextData } from 'src/context/workspace'
import PowerdocsCustomize from 'src/pages/settings/powerdocs-customize'
import { Icon } from '@iconify/react'

export default function Settings() {
  const { t } = useTranslation()

  const [profile, setPorfile] = useState<any>({})
  const [isVisible, setIsVisible] = React.useState(false)

  const toggleVisibility = () => setIsVisible(!isVisible)
  const loadUser = () => {
    const userString = localStorage.getItem('user')
    if (userString) {
      setPorfile(JSON.parse(userString))
    }
    api
      .get('/agencies/profile')
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setPorfile(res.data)
      })
      .catch((e) => {
        console.log('Error loading current profile')
      })
  }
  useEffect(() => {
    loadUser()
  }, [])

  const { tab } = useParams()
  const [selected, setSelected] = React.useState(tab)
  const [workspace, setWorkspace] = useState(null)
  const updateProfile = () => {
    api
      .get('/agencies/profile')
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setPorfile(res.data)
      })
      .catch((e) => {
        console.log('Error loading current profile')
      })
  }
  const { activeWorkspace } = useWorkspaceContextData()
  useEffect(() => {
    if (profile && activeWorkspace) {
      const ws = (profile.workspaces || []).filter((w) => w.uuid === activeWorkspace).pop()
      if (ws) setWorkspace(ws)
    }
  }, [profile, activeWorkspace])
  const navigate = useNavigate()
  const tabs = {
    profile: 'Profile',
    company: 'Company details',
    powerdocs: 'Personalize Powerdocs AI',
    billing: 'Billing details',
  }
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <div className="flex flex-col w-full h-screen p-3 overflow-y-auto">
        
        <Breadcrumbs className={'pl-2 pt-4'}>
          <BreadcrumbItem onClick={() => navigate('/')}>
            <Icon icon={'lucide:home'} /> Home
          </BreadcrumbItem>
          <BreadcrumbItem onClick={() => navigate('/settings')}>Settings</BreadcrumbItem>
          <BreadcrumbItem>{tabs[selected] || 'Settings'}</BreadcrumbItem>
        </Breadcrumbs>

        {/*  Tabs */}
        <div className="flex flex-col gap-3 w-full h-full px-2 2xl:px-8 max-w-[750px]">
          <Tabs
            fullWidth
            aria-label="Settings"
            selectedKey={selected}
            onSelectionChange={(key) => {
              setSelected(key)
              navigate(`/settings/${key}`)
            }}
            classNames={{
              base: 'mt-6',
              cursor: 'bg-content1 dark:bg-content1',
              panel: 'w-full p-0 pt-4',
            }}
          >
            <Tab key="profile" title="Profile">
              <AccountSetting />
            </Tab>
            <Tab key="company" title="Company details">
              <CompanyDetails workspace={workspace} setWorkspace={setWorkspace} />
            </Tab>
            <Tab key="powerdocs" title="Personalize Powerdocs AI">
              <PowerdocsCustomize
                workspace={workspace}
                setWorkspace={setWorkspace}
                profile={profile}
                loadUser={loadUser}
              />
            </Tab>
            {/* <Tab key="appearance" title="Appearance">
              <AppearanceSetting />
            </Tab> */}
            <Tab key="billing" title="Billing details">
              <BillingSetting client={profile} loadUser={loadUser} />
            </Tab>
          </Tabs>
        </div>

        <Spacer y={20} />
      </div>
    </>
  )
}
