import { Button, Divider, Input, Textarea, ScrollShadow } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import { useState } from 'react';

import Mixpanel from 'src/lib/mixpanel'

export default function CoverPageContentDetails({ onClose, coverPageDetails }) {
  const { 
    documentDate, setDocumentDate, 
    title, setTitle, 
    createdBy, setCreatedBy, 
    proposedTo, setProposedTo, 
    updateCoverPageDetails, 
    logo, open, deleteFile, getRootProps, getInputProps 
  } = coverPageDetails;

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-col sticky top-0 bg-white">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-medium">Cover Page Details</h2>
          <Button isIconOnly variant="light" onPress={onClose} size="sm">
            <Icon icon="ic:round-close" className="text-gray-500 text-xl" />
          </Button>
        </div>
        <Divider className="my-2 h-[3px]" />
      </div>

      <div className="flex flex-col justify-between items-center mb-4 gap-4">
        <div className="flex flex-col w-full gap-8">
          <Input
            variant="faded"
            value={documentDate}
            onChange={(e) => setDocumentDate(e.target.value)}
            onBlur={updateCoverPageDetails}
            label="Document Date"
            labelPlacement="outside"
            classNames={{
              inputWrapper: 'pl-2 border-0',
              label: 'text-sm uppercase !text-gray-500',
            }}
          />
          <Textarea
            variant="faded"
            value={title}
            label="Title"
            labelPlacement="outside"
            onChange={(e) => setTitle(e.target.value)}
            onBlur={updateCoverPageDetails}
            classNames={{
              inputWrapper: 'pl-2 border-0',
              label: 'text-sm uppercase !text-gray-500',
            }}
            maxRows={5}
          />
          <Textarea
            variant="faded"
            label="Created By"
            labelPlacement="outside"
            value={createdBy}
            onChange={(e) => setCreatedBy(e.target.value)}
            onBlur={updateCoverPageDetails}
            classNames={{
              inputWrapper: 'pl-2 border-0',
              label: 'text-sm uppercase !text-gray-500',
            }}
            maxRows={5}
          />
          <Textarea
            variant="faded"
            label="Proposed To"
            labelPlacement="outside"
            value={proposedTo}
            onChange={(e) => setProposedTo(e.target.value)}
            onBlur={updateCoverPageDetails}
            classNames={{
              inputWrapper: 'pl-2 border-0',
              label: 'text-sm uppercase !text-gray-500',
            }}
            maxRows={5}
          />
          <div {...getRootProps({ className: 'flex flex-col gap-2 mt-2' })}>
            <input {...getInputProps()} />
            <h3 className="text-sm uppercase !text-gray-500">Logo</h3>
            <div className="flex flex-col gap-1 justify-center items-center p-4 border-1 border-secondary rounded-xl">
              {logo ? (
                <>
                  <img src={logo} className="w-[120px] mb-5" />
                  <div className="flex gap-2">
                    <Button variant="solid" color="secondary" onPress={open}>
                      Select file
                    </Button>
                    <Button variant="bordered" color="danger" onPress={deleteFile}>
                      Delete File
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <p className="font-medium text-xl">Select a file or drag and drop here</p>
                  <span className="text-sm font-regular">JPG or PNG, file size no more than 10MB</span>
                  <Button variant="solid" color="secondary" onPress={open}>
                    Select file
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}