import { createSuggestionsItems } from '@harshtalks/slash-tiptap'

export const createSuggestions = ({ onOpenMediaModal }: any) =>
  createSuggestionsItems([
    {
      title: 'Text',
      icon: 'ph:text-aa',
      searchTerms: ['title'],
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setNode('paragraph').run()
      },
    },
    {
      title: 'Heading 1',
      icon: 'lucide:heading-1',
      searchTerms: ['title'],
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setNode('heading', { level: 1 }).run()
        // editor.commands.insertContent({
        //   type: 'cover',
        //   attrs: {},
        //   content: [
        //     {
        //       type: 'text', // Keep the parsed content as HTML here
        //       text: 'lala land',
        //     },
        //   ],
        // })
      },
    },
    {
      title: 'Heading 2',
      icon: 'lucide:heading-2',
      searchTerms: ['title'],
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setNode('heading', { level: 2 }).run()
      },
    },
    {
      title: 'Heading 3',
      icon: 'lucide:heading-3',
      searchTerms: ['title'],
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setNode('heading', { level: 3 }).run()
      },
    },
    {
      title: 'Bulleted list',
      icon: 'lucide:list',
      searchTerms: ['list', 'bullet list', 'points'],
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleBulletList().run()
      },
    },
    {
      title: 'Numbered list',
      icon: 'fe:list-order',
      searchTerms: ['ordered', 'point', 'numbers'],
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleOrderedList().run()
      },
    },
    {
      title: 'Table',
      icon: 'lucide:table',
      searchTerms: ['table'],
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
      },
    },
    {
      title: 'Image',
      icon: 'lucide:image',
      searchTerms: ['picture', 'image', 'figure'],
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).run()
        if (window.documentEditImage) {
          window.documentEditImage(null)
        } else {
          onOpenMediaModal()
        }
        /*.setImage({ src: 'https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg' })
        .run()*/
      },
    },
  ])
