import { useState } from 'react'
import { Button, Divider, Input, Switch, Tooltip } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import toast from 'react-hot-toast'
import Mixpanel from 'src/lib/mixpanel'

export default function DocSidebarContentShare({ onClose, doc, variant }) {
  const [shareLinkEnabled, setShareLinkEnabled] = useState(true)
  const url = `${document.location.origin}/${variant ?? 'document'}/${doc.uuid}`

  const handleCopyLink = () => {
    try {
      const url = `${document.location.origin}/${variant}/${doc.uuid}`
      navigator.clipboard.writeText(url)
      toast.success('Link copied to clipboard')

      Mixpanel.track('Document link copied', {
        location: 'Document Sidebar',
        document_id: doc.uuid,
        link: url,
        variant,
      })
    } catch (error) {
      toast.error('Failed to copy the link. Please try again.')
    }
  }

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-col sticky top-0 bg-white">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-medium">Share</h2>
          <Button isIconOnly variant="light" onPress={onClose} size="sm">
            <Icon icon="ic:round-close" className="text-gray-500 text-xl" />
          </Button>
        </div>
        <Divider className="my-2 h-[3px]" />
      </div>

      <div className="flex flex-col justify-between items-center mb-4">
        <div className="mt-4 w-full">
          <div className="flex justify-between items-center">
            <p className="text-sm text-gray-500 mb-2">SHARE LINK</p>
            <div className="flex items-center justify-between mb-2">
              <Switch isSelected={shareLinkEnabled} onValueChange={setShareLinkEnabled} color="secondary" isDisabled />
            </div>
          </div>
          <Input
            value={url}
            isReadOnly
            className="mb-4"
            endContent={
              <Tooltip content="Copy link">
                <Button onPress={handleCopyLink} variant="light" size="sm" isIconOnly>
                  <Icon icon="lucide:copy" className="text-secondary" width="20" />
                </Button>
              </Tooltip>
            }
          />
          <p className="text-xs text-gray-400 mb-4">
            Your document is visible only to people who have this link and isn't publicly visible. Comments are not
            visible to anyone other than you.
          </p>
        </div>
      </div>
    </div>
  )
}
