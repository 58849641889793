import { Button, Divider, Slider, Listbox, ListboxItem } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import { useState } from 'react';

import Mixpanel from 'src/lib/mixpanel'

export default function CoverPageContentDesign({ onClose, coverPageDesignProps }) {
  const { coverPageDesign, setCoverPageDesign } = coverPageDesignProps;
  
  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-col sticky top-0 bg-white">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-medium">Cover Page Design</h2>
          <Button isIconOnly variant="light" onPress={onClose} size="sm">
            <Icon icon="ic:round-close" className="text-gray-500 text-xl" />
          </Button>
        </div>
        <Divider className="my-2 h-[3px]" />
      </div>

      <div className="flex flex-col justify-between items-center mb-4 gap-4">
        <div className="mt-4 w-full">
          <p className="text-sm text-gray-500 mb-2">DESIGN & LAYOUT</p>
        </div>

        <div className="flex flex-col w-full gap-8">
          <div className="design-options">
            {["design1", "design2", "design3", "design4", "design5", "design6"].map((design) => (
              <Button 
                key={design} 
                className={`design-button ${coverPageDesign === design ? "selected" : ""}`} 
                onPress={() => setCoverPageDesign(design)}
              >
                {design}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}