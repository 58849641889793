import { mergeAttributes, Node } from '@tiptap/core'
import { NodeSelection } from 'prosemirror-state'
import { DOMSerializer } from '@tiptap/pm/model'

const stopPrevent = <T extends Event>(e: T): T => {
  ;(e as Event).stopPropagation()
  ;(e as Event).preventDefault()

  return e
}

export interface TableRowOptions {
  HTMLAttributes: Record<string, any>
}

const isScrollable = function (ele: any) {
  const hasScrollableContent = ele.scrollHeight > ele.clientHeight
  const overflowYStyle = window.getComputedStyle(ele).overflowY
  const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1
  return hasScrollableContent && !isOverflowHidden
}

const getScrollableParent = function (ele: any): any {
  return !ele || ele === document.body ? document.body : isScrollable(ele) ? ele : getScrollableParent(ele.parentNode)
}

const getElementWithAttributes = (name: string, attrs?: Record<string, any>, events?: Record<string, any>) => {
  const el = document.createElement(name)
  if (!el) throw new Error(`Element with name ${name} can't be created.`)

  if (attrs) {
    Object.entries(attrs).forEach(([key, val]) => el.setAttribute(key, val))
  }

  if (events) {
    Object.entries(events).forEach(([key, val]) => el.addEventListener(key, val))
  }

  return el
}

export const TableRow = Node.create<TableRowOptions>({
  name: 'tableRow',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  content: '(tableCell | tableHeader)*',
  tableRole: 'row',

  parseHTML() {
    return [{ tag: 'tr' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['tr', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addNodeView() {
    return ({ editor, HTMLAttributes, getPos }) => {
      const pos = () => (getPos as () => number)()
      const scrollableParent = getScrollableParent(editor.options.element) as HTMLDivElement

      let isCursorInsideControlSection = false

      const actions = {
        deleteRow: () => {
          const resolvedCell = editor.state.doc.resolve(pos())
          const { tr } = editor.state
          if (resolvedCell.nodeAfter) {
            tr.delete(resolvedCell.pos, resolvedCell.pos + resolvedCell.nodeAfter.nodeSize)
          }
          editor.view.dispatch(tr)
        },
        selectRow: () => {
          const from = pos()
          const resolvedFrom = editor.state.doc.resolve(from)
          const nodeSel = new NodeSelection(resolvedFrom)
          editor.view.dispatch(editor.state.tr.setSelection(nodeSel))
        },
        moveRowUp: () => {
          const { state, view } = editor
          const { tr } = state
          const from = pos()
          const resolvedFrom = state.doc.resolve(from)
          const nodeSel = new NodeSelection(resolvedFrom)
          const currentRow = nodeSel.node
          const previousRowResolved = state.doc.resolve(from - 1)
          if (!previousRowResolved.node()) return
          const previousRow = previousRowResolved.node()
          const previousRowStart = from - previousRow.nodeSize

          tr.delete(previousRowStart, nodeSel.to)
            .insert(previousRowStart, currentRow)
            .insert(previousRowStart + currentRow.nodeSize, previousRow)

          view.dispatch(tr)
        },
        moveRowDown: () => {
          const { state, view } = editor
          const { tr } = state
          const from = pos()
          const resolvedFrom = state.doc.resolve(from)
          const nodeSel = new NodeSelection(resolvedFrom)
          const currentRow = nodeSel.node
          const currentRowEnd = from + currentRow.nodeSize
          const nextRowResolved = state.doc.resolve(currentRowEnd + 1)
          const nextRow = nextRowResolved.node()
          const nextRowStart = currentRowEnd
          const nextRowEnd = nextRowStart + nextRow.nodeSize

          tr.delete(from, nextRowEnd)
            .insert(from, nextRow)
            .insert(from + nextRow.nodeSize, currentRow)

          view.dispatch(tr)
        },
      }

      const showControls = () => {
        controlSection.classList.remove("hidden");
      };

      const hideControls = () => {
        if (!controlSection.matches(':hover')) {
          controlSection.classList.add("hidden");
        }
      };

      const controlSection = getElementWithAttributes(
        'section',
        {
          class:
            'absolute hidden flex items-center bg-black rounded-full h-[55px] min-h-[55px] max-h-[55px] flex-col z-50 cursor-pointer justify-center w-[20px]',
          contenteditable: 'false',
        },
        {
          mouseenter: showControls,
          mouseleave: hideControls,
        },
      );

      const deleteButton = getElementWithAttributes(
        'button',
        { class: 'text-xs opacity-75 hover:opacity-100 text-white' },
        { click: (e: any) => { if (e) stopPrevent(e); actions.deleteRow(); } },
      );

      const moveUpButton = getElementWithAttributes(
        'button',
        { class: 'text-xs opacity-75 hover:opacity-100 text-white' },
        { click: (e: any) => { if (e) stopPrevent(e); actions.moveRowUp(); } },
      );

      const moveDownButton = getElementWithAttributes(
        'button',
        { class: 'text-xs opacity-75 hover:opacity-100 text-white text-[9px]' },
        { click: (e: any) => { if (e) stopPrevent(e); actions.moveRowDown(); } },
      );

      const tableRow = getElementWithAttributes(
        'tr',
        { 
          ...HTMLAttributes,
          style: 'position: relative;',
        },
        {
          mouseenter: showControls,
          mouseleave: hideControls,
        },
      );

      moveUpButton.innerHTML = '<i class="fas fa-arrow-up"></i>';
      moveDownButton.innerHTML = '<i class="fas fa-arrow-down"></i>';
      deleteButton.innerHTML = '<i class="fas fa-trash"></i>';

      if (editor.isEditable) {
        controlSection.append(moveUpButton);
        controlSection.append(deleteButton);
        controlSection.append(moveDownButton);
        document.body.append(controlSection);
      }

      const repositionControlsCenter = () => {
        setTimeout(() => {
          const rowCoords = tableRow.getBoundingClientRect();
          controlSection.style.top = `${
            rowCoords.top + (rowCoords.height / 2 - 27) + document.documentElement.scrollTop
          }px`;
          controlSection.style.left = `${rowCoords.x + document.documentElement.scrollLeft - 12}px`;
          controlSection.style.height = `${rowCoords.height + 1}px`;
        });
      };

      setTimeout(() => {
        repositionControlsCenter();
      }, 100);

      editor.on('selectionUpdate', repositionControlsCenter);
      editor.on('update', repositionControlsCenter);
      scrollableParent?.addEventListener('scroll', repositionControlsCenter);
      document.addEventListener('scroll', repositionControlsCenter);

      const destroy = () => {
        controlSection.remove();
        editor.off('selectionUpdate', repositionControlsCenter);
        editor.off('update', repositionControlsCenter);
        scrollableParent?.removeEventListener('scroll', repositionControlsCenter);
        document.removeEventListener('scroll', repositionControlsCenter);
      };

      return {
        dom: tableRow,
        contentDOM: tableRow,
        destroy,
      };
    };
  },
});