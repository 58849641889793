import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import api from 'src/lib/api'
import { useNavigate } from 'react-router-dom'
import { emitter } from 'src/lib/EventEmitter'
import { useWorkspaceContextData } from 'src/context/workspace'

export default function DeleteDocumentModal({
  openedDeleteDocument,
  onOpenDeleteDocumentChange,
  documentToEdit,
  updateDocuments,
}: {
  openedDeleteDocument: boolean
  onOpenDeleteDocumentChange: (isOpen: boolean) => void
  documentToEdit: any
  updateDocuments: () => Promise<void>
}) {
  const { activeWorkspace } = useWorkspaceContextData()
  const [deletingDocument, setDeletingDocument] = useState<boolean>(false)
  const navigate = useNavigate()
  const deleteDocument = async () => {
    setDeletingDocument(true)
    const res = await api.delete('/urs/' + documentToEdit?.uuid)
    await updateDocuments()
    emitter.emit('updateSubmissionCount', { step: -1, uuid: activeWorkspace })
    setDeletingDocument(false)
    onOpenDeleteDocumentChange(false)
  }

  // Handle Enter key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!openedDeleteDocument || deletingDocument) return

      if (event.key === 'Enter') {
        event.preventDefault() // Prevent accidental form submission
        deleteDocument()
      } else if (event.key === 'Escape') {
        event.preventDefault()
        onOpenDeleteDocumentChange(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [openedDeleteDocument, deletingDocument])

  return (
    <Modal size={'3xl'} isOpen={openedDeleteDocument} onOpenChange={onOpenDeleteDocumentChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1"></ModalHeader>
            <ModalBody>
              <div className="flex flex-col grow gap-7">
                <div className="flex flex-col items-center">
                  <p className="text-2xl font-semibold">Delete Document</p>
                  <p className="text-base text-center">
                    If you want to proceed with this action please click the confirm button below.
                    <br /> Please Note that this action cannot be reverted, and the document will be permanently
                    deleted.
                  </p>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="default" variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button color="danger" onPress={deleteDocument} isDisabled={deletingDocument}>
                {deletingDocument ? 'Removing ' : 'Confirm '}
                {deletingDocument && <Spinner size={'sm'} />}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
