import { Button, Modal, ModalBody, ModalContent, ModalFooter } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import { useSocketContextData } from 'src/context/socket'

export default function PersonalizePowerdocsModal({
  isOpen,
  onOpenChange,
}: {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}) {
  const navigate = useNavigate()
  const { io } = useSocketContextData()
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="3xl"
      backdrop="blur"
      classNames={{ backdrop: 'bg-[#285F67]/50' }}
    >
      <ModalContent className="rounded-xl bg-white p-8">
        {(onClose) => (
          <>
            <ModalBody className="flex flex-col items-center justify-center text-center">
              <div className="w-3/4 flex flex-col items-center">
                <img src="/img/modal-modify-powerdocs-ai.png" alt="Illustration" className="mb-6 w-60" />
                <h3>SET IT AND FORGET IT</h3>
                <h2 className="text-3xl font-bold text-gray-900">Personalize Powerdocs AI</h2>
                <p className="text-gray-600 text-base mt-2">
                  Customize your default settings so Powerdocs generates your documents with perfection. This saves you
                  time and allows you to work extremely fast and effectively.
                </p>
              </div>
            </ModalBody>
            <ModalFooter className="flex justify-center">
              <Button
                color="secondary"
                className="px-6 py-3 text-lg rounded-full"
                onPress={() => {
                  navigate('/settings/powerdocs')
                  onClose()
                }}
              >
                Start now
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
