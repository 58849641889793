import { Button, Divider, ScrollShadow, Tab, Tabs } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import MessageCard from 'src/components/ai/message-card'
import React from 'react'

export default function DocSidebarComments({ onClose, doc, onResolveClick, variant }) {
  return (
    <div className="flex flex-col w-full h-full gap-4">
      <div className="flex flex-col sticky top-0 bg-white">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-medium">Comments</h2>
          <Button isIconOnly variant="light" onPress={onClose} size="sm">
            <Icon icon="ic:round-close" className="text-gray-500 text-xl" />
          </Button>
        </div>
        <Divider className="my-2 h-[3px]" />
      </div>

      <div className="flex flex-col h-full items-center mb-4">
        <div className="w-full h-full">
          <Tabs aria-label="Options">
            <Tab key="active" title="Comments">
              <ScrollShadow>
                {doc.comments
                  .filter((item: any) => !item.isResolved && item.variant === variant)
                  .map((comment: any) => (
                    <MessageCard
                      key={comment.uuid}
                      // attempts={index === 1 ? 2 : 1}
                      avatar={comment.username}
                      message={comment.text}
                      messageClassName={'border text-black bg-white w-full'}
                      showFeedback={false}
                      messageContainerClass={'w-full'}
                      showResolveButton
                      onMessageClick={() => {
                        const element = document.querySelector(`[data-comment-id="${comment.uuid}"]`)
                        if (element) {
                          element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
                        }
                      }}
                      onResolveClick={() => {
                        onResolveClick(comment)
                      }}
                    />
                  ))}
              </ScrollShadow>
            </Tab>
            <Tab key="resolved" title="Resolved">
              <ScrollShadow>
                {doc.comments
                  .filter((item: any) => item.isResolved && item.variant === variant)
                  .map((comment: any) => (
                    <MessageCard
                      key={comment.uuid}
                      // attempts={index === 1 ? 2 : 1}
                      avatar={comment.username}
                      message={comment.text}
                      messageClassName={'border text-black bg-white w-full'}
                      showFeedback={false}
                      messageContainerClass={'w-full'}
                      onMessageClick={() => {
                        const element = document.querySelector(`[data-comment-id="${comment.uuid}"]`)
                        if (element) {
                          element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
                        }
                      }}
                    />
                  ))}
              </ScrollShadow>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}
