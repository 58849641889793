import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'
import React, { useState } from 'react'
import api from 'src/lib/api'
import { useNavigate } from 'react-router-dom'
import { useWorkspaceContextData } from 'src/context/workspace'

export default function DeleteClientModal({
  openedDeleteClient,
  onOpenDeleteClientChange,
  clientToEdit,
}: {
  openedDeleteClient: boolean
  onOpenDeleteClientChange: (isOpen: boolean) => void
  clientToEdit: any
}) {
  const [deletingClient, setDeletingClient] = useState<boolean>(false)
  const { removeClient } = useWorkspaceContextData()
  const navigate = useNavigate()
  const createClient = async () => {
    setDeletingClient(true)
    const res = await api.delete('/clients/' + clientToEdit?.uuid)
    removeClient?.(clientToEdit)
    onOpenDeleteClientChange(false)
    navigate('/')
  }
  return (
    <Modal size={'3xl'} isOpen={openedDeleteClient} onOpenChange={onOpenDeleteClientChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1"></ModalHeader>
            <ModalBody>
              <div className="flex flex-col grow gap-7">
                <div className="flex flex-col items-center">
                  <p className="text-2xl font-semibold">Delete client</p>
                  <p className="text-base text-center">
                    If you want to proceed with this action please click the confirm button below.
                    <br /> Please Note that this action will result in the removal of projects and document associated
                    to this client.
                  </p>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="default" variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button color="danger" onPress={createClient} isDisabled={deletingClient}>
                Confirm
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
