import api from 'src/lib/api'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash-es'
import { emitter } from 'src/lib/EventEmitter'

import {
  Button,
  useDisclosure,
  Spinner,
  Table,
  TableColumn,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Input,
  SelectItem,
  Select,
} from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { useNavigate, useParams } from 'react-router-dom'
import Mixpanel from 'src/lib/mixpanel'
import { useSocketContextData } from 'src/context/socket'
import { useWorkspaceContextData } from 'src/context/workspace'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import CreateUrdWindow from 'src/pages/ursList/CreateUrdWindow'
import toast from 'react-hot-toast'
import { SubscribeLimitDocument } from 'src/components/modals/subscribe-limit-document'
import { useSubscriptionContext } from 'src/context/subscription'
import RenameDocumentModal from 'src/pages/ursList/RenameDocumentModal'
import DeleteDocumentModal from 'src/pages/ursList/DeleteDocumentModal'
import DuplicateDocumentModal from 'src/pages/ursList/DuplicateDocumentModal'
import AssingCreateClientModal from 'src/pages/submissions/AssingCreateClientModal'
dayjs.extend(relativeTime)

export default function Submission() {
  const { t } = useTranslation()
  const [initialized, setInitialized] = useState<boolean>(false)
  const { activeWorkspace, checkClient } = useWorkspaceContextData()
  const [user, setUser] = useState(null)
  const loadUser = () => {
    const userString = localStorage.getItem('user')
    if (userString) {
      setUser(JSON.parse(userString))
    }
    api
      .get('/agencies/profile')
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setUser(res.data)
      })
      .catch((e) => {
        console.log('Error loading current profile')
      })
  }
  useEffect(() => {
    loadUser()
  }, [])
  const { io } = useSocketContextData()
  // Create new client modal and listen to "new" in the URL
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isSubscriptionModalOpen,
    onOpen: openSubscriptionModal,
    onClose: closeSubscriptionModal,
  } = useDisclosure()
  const handleOpen = () => {
    onOpen()
  }
  const params = useParams()
  const [project, setProject] = useState({})
  const loadProject = async () => {
    const res = await api.get(`/documents/`)
    setProject(res.data)
  }

  useEffect(() => {
    if (params.projectUUID) {
      loadProject()
    }
  }, [params])

  const [creating, setCreating] = useState<boolean>(false)

  const createNewUrs = async () => {
    Mixpanel.track('Document creation intent', {
      location: 'Document hub',
    })
    onOpen()
  }

  const [sendingEmail, setSendingEmail] = useState<boolean>(false)
  const resendVerification = async () => {
    if (!sendingEmail) {
      setSendingEmail(true)
      const res = await api.post('/auth/resend')
      setSendingEmail(false)
      toast.success('Verification email has been sent successfully')
    }
  }
  const { currentLimitations } = useSubscriptionContext()
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure()
  const { isOpen: isDuplicateOpen, onOpen: onDuplicateOpen, onOpenChange: onDuplicateOpenChange } = useDisclosure()
  const { isOpen: isEditOpen, onOpen: onOpenEditProject, onOpenChange: onEditOpenChange } = useDisclosure()
  const { isOpen: isOpenAssignClient, onOpen: onOpenAssignClient, onOpenChange: onOpenedAssignClient } = useDisclosure()
  const [documentToEdit, setDocumentToEdit] = useState<any>({})
  const [assigningDocument, setAssigningDocument] = useState<boolean>(false)
  const { clientsFullList } = useWorkspaceContextData()

  const onAssignToClient = async (documentUUID: string, clientUUID: string, tempClient?: any) => {
    const client = clientsFullList.filter((c) => c.uuid === clientUUID).pop()
    if (client || tempClient) {
      setAssigningDocument(true)
      await io.emitWithAck('assignDocumentToClient', {
        token: localStorage.getItem('accessToken'),
        documentUUID,
        clientUUID,
      })
      setAssigningDocument(false)
      setDocuments((prev) => {
        const copyDocs = [...prev]
        const documentIndex = copyDocs.findIndex((d) => d.uuid === documentUUID)
        if (documentIndex > -1) {
          copyDocs[documentIndex].Client = client ?? tempClient
        }
        return copyDocs
      })
    }
  }
  const getPhaseClass = (phase: string) => {
    switch (phase) {
      case 'Submitted':
        return 'bg-[#C3E3E8]'
      case 'Follow-up':
        return 'bg-[#FACD94]'
      case 'Declined':
        return 'bg-[#FF97A9]'
      case 'Accepted':
        return 'bg-[#9DE1C3]'
      default:
        return ''
    }
  }
  const [filterValue, setFilterValue] = useState<string>('')
  const onClear = () => {
    setFilterValue('')
  }
  // const [filterStatus, setFilterStatus] = useState(new Set(['Declined', 'Follow-up', 'Submitted', 'Accepted']))
  const [filterStatus, setFilterStatus] = useState(new Set())
  const statusList = [
    { key: 'Submitted', label: 'Submitted' },
    { key: 'Follow-up', label: 'Follow-up' },
    { key: 'Declined', label: 'Declined' },
    { key: 'Accepted', label: 'Accepted' },
  ]
  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <div className="flex gap-1 w-full">
            <Input
              isClearable
              className="w-full sm:max-w-[44%] md:max-w-[20%]"
              placeholder="Search ..."
              startContent={<Icon icon={'lucide:search'} />}
              value={filterValue}
              onClear={() => onClear()}
              onValueChange={setFilterValue}
            />
            <Select
              className="max-w-[150px]"
              placeholder="Filter"
              startContent={<Icon icon={'lucide:filter'} />}
              selectedKeys={filterStatus}
              selectionMode="multiple"
              color={`${filterStatus.size > 0 ? 'primary' : 'default'}`}
              label={''}
              renderValue={() => (filterStatus.size === 4 ? 'Filter (all)' : `Filter (${filterStatus.size})`)}
              onSelectionChange={(selected) => {
                setFilterStatus(selected) // Update the state with the new selection

                // Track the filter change event in Mixpanel
                Mixpanel.track('Filter status changed', {
                  selected_filters: Array.from(selected).join(', '), // Log the selected filters
                  location: 'Subbmissions page',
                })
              }}
            >
              {statusList.map((s) => (
                <SelectItem aria-labelbyzz={s.key} key={s.key}>
                  {s.label}
                </SelectItem>
              ))}
            </Select>
          </div>
          <div className="flex gap-3">
            <Button
              onPress={() => {
                createNewUrs()
              }}
              color="secondary"
              size="md"
              radius="md"
              isDisabled={
                creating || !user?.isActive || (!currentLimitations?.isPro && !currentLimitations?.canAddDocument)
              }
              startContent={
                creating ? (
                  <Spinner size={'md'} />
                ) : (
                  <Icon className="flex-none" icon="solar:add-circle-linear" width={20} />
                )
              }
            >
              Create document
            </Button>
          </div>
        </div>
      </div>
    )
  }, [filterStatus, filterValue, currentLimitations, user, creating])

  function EmptyStateComponent({ onCreate }: { onCreate?: () => void }) {
    return (
      <div className="flex flex-col items-center justify-center py-10">
        <Icon icon="lucide:folder-open" width={50} className="text-gray-400 mb-4" />
        <h3 className="text-lg font-semibold text-gray-600">No documents found</h3>
        <p className="text-gray-500 mt-2 mb-6 text-sm">
          It looks like there are no documents here yet. Start by creating a new document.
        </p>
        <Button
          onPress={() => {
            createNewUrs()
          }}
          color="secondary"
          size="md"
          radius="md"
          isDisabled={
            creating || !user?.isActive || (!currentLimitations?.isPro && !currentLimitations?.canAddDocument)
          }
          startContent={
            creating ? (
              <Spinner size={'md'} />
            ) : (
              <Icon className="flex-none" icon="solar:add-circle-linear" width={20} />
            )
          }
        >
          Create document
        </Button>
      </div>
    )
  }

  const [documents, setDocuments] = useState<any[]>([])

  const loadDocuments = useCallback(
    async (status: string | null, name: string) => {
      const res = await api.get(`/urs/list/${activeWorkspace}`, {
        params: {
          status,
          name,
        },
      })
      setDocuments(res.data)
    },
    [activeWorkspace],
  )
  const updateDocuments = async () => {
    return loadDocuments(Array.from(filterStatus).join(','), filterValue)
  }

  const debouncedLoadDocuments = useCallback(
    debounce((status: string, name: string) => {
      loadDocuments(status, name)
    }, 300), // Adjust debounce delay as needed
    [loadDocuments],
  )
  useEffect(() => {
    debouncedLoadDocuments(Array.from(filterStatus).join(','), filterValue)
    // Cleanup to cancel debounce on unmount or updates
    return () => debouncedLoadDocuments.cancel()
  }, [filterStatus, filterValue, debouncedLoadDocuments])

  const updateDocumentStatus = async (uuid: string, phase: string, prevPhase: string) => {
    const resStatus = await io.emitWithAck('updateDocumentStatus', {
      token: localStorage.getItem('accessToken'),
      uuid,
      phase,
    })
    const doc = documents.filter((doc) => doc.uuid === uuid).pop()
    const clientUUID = doc.Client?.uuid
    if (clientUUID) {
      checkClient?.(clientUUID, uuid, phase)
    }
    if (prevPhase !== 'Accepted' && prevPhase !== 'Declined' && (phase === 'Declined' || phase === 'Accepted')) {
      // @ts-ignore
      emitter.emit('updateSubmissionCount', { step: -1, uuid: activeWorkspace })
    }
    if ((prevPhase === 'Accepted' || prevPhase === 'Declined') && phase !== 'Declined' && phase !== 'Accepted') {
      emitter.emit('updateSubmissionCount', { step: 1, uuid: activeWorkspace })
    }
  }

  {
    /* Group document by project */
  }
  const groupedDocuments = documents.reduce((acc, doc) => {
    const projectId = doc.proposalProjectId || 'unassigned'

    if (!acc[projectId]) {
      acc[projectId] = {
        projectName: doc?.id || 'Untitled Project',
        clientName: doc.Client?.name || 'Unknown Client',
        documents: [],
      }
    }

    acc[projectId].documents.push(doc)
    return acc
  }, {} as Record<string, { projectName: string; clientName: string; documents: typeof documents }>)

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <CreateUrdWindow
        isOpen={isOpen}
        onClose={onClose}
        projectUUID={params.projectUUID}
        showSubscriptionError={() => {
          setTimeout(() => openSubscriptionModal(), 1000)
        }}
      />
      <SubscribeLimitDocument isOpen={isSubscriptionModalOpen} onClose={closeSubscriptionModal} />
      <div className="flex flex-col w-full max-w-screen-xl h-screen p-3">
        <div className="flex w-full pt-8 pb-6 px-2 2xl:px-8">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold text-default-900 lg:text-3xl">
                Document hub
              </h1>
              <p className="text-small lg:text-medium">
                Manage and track all your documents and projects in one place
              </p>
            </div>
          </header>
        </div>

        {user && !user?.isActive && (
          <div className=" w-full px-2 2xl:px-8">
            <div className={'flex w-full p-2 mb-3 rounded-xl bg-warning-100'}>
              <p className={'text-sm flex flex-row gap-1 flex-1 '}>
                {'⚠️ You need to verify your email address first –'}
                <span className={'cursor-pointer underline'} onClick={resendVerification}>
                  click here to resend the verification email
                </span>
              </p>
              {sendingEmail && <Spinner size={'sm'} />}
            </div>
          </div>
        )}
        <div className="flex w-full pb-2 max-w-full max-h-full px-2 2xl:px-8 overflow-y-auto">
          <Table isHeaderSticky aria-label="Document List" topContent={topContent} topContentPlacement="outside">
            <TableHeader>
              <TableColumn className={'text-black bg-[#C3E3E8]'}>Project</TableColumn>
              <TableColumn className={'text-black bg-[#C3E3E8]'}>Client</TableColumn>
              <TableColumn className={'text-black bg-[#C3E3E8]'}>Project phase</TableColumn>
              <TableColumn className={'text-black bg-[#C3E3E8]'}>Status</TableColumn>
              {/* <TableColumn className={'text-black bg-[#C3E3E8]'}>Platform</TableColumn> */}
              <TableColumn className={'text-black bg-[#C3E3E8]'}>Created</TableColumn>
              <TableColumn className={'text-black bg-[#C3E3E8]'}>Last edit</TableColumn>
              <TableColumn className={'text-black bg-[#C3E3E8]'}>Actions</TableColumn>
            </TableHeader>

            <TableBody emptyContent={<EmptyStateComponent onCreate={createNewUrs} />}>
              {Object.entries(groupedDocuments).map(([projectId, projectGroup]) => (
                <React.Fragment key={projectId}>

                  {projectGroup.documents.map((doc) => (
                    <TableRow
                      key={doc.uuid}
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/client/${doc.Client?.uuid}/document/${doc.uuid}/${
                            doc.documentType?.toLowerCase?.() ?? 'proposal'
                          }`,
                        )
                      }>

                      <TableCell>
                        <div className="flex items-center h-full">
                          <span className="text-black line-clamp-1">{doc?.title}</span>
                        </div>
                      </TableCell>

                      <TableCell className={'capitalize'}>
                        {doc.Client ? (
                          <span className={'w-full'}>{doc.Client.name}</span>
                        ) : (
                          <div
                            className={
                              'text-warning-500 text-underline flex items-center'
                            }
                            onClick={() => {
                              setDocumentToEdit(doc)
                              onOpenAssignClient()
                            }}
                          >
                            {documentToEdit.uuid === doc.uuid && assigningDocument ? (
                              <>
                                <Spinner size={'sm'} />
                                <span>Assigning client...</span>
                              </>
                            ) : (
                              <>
                                <Icon icon={'lucide:octagon-alert'} className="mr-1" />
                                <span>{'Unknown'}</span>
                              </>
                            )}
                          </div>
                        )}
                      </TableCell>

                      <TableCell>
                        <div className="flex items-center gap-1">
                          {['Proposal', 'Quotation', 'Offer', 'Contract'].map((phase, index) => (
                            <span
                              key={phase}
                              className={`w-3 h-3 rounded-full border ${
                                index < ['PROPOSAL', 'QUOTATION', 'OFFER', 'CONTRACT'].indexOf(doc.documentType) + 1 ? 'bg-secondary border-secondary' : 'border-secondary'
                              }`}
                            ></span>
                          ))}
                        </div>
                        <span className="capitalize text-tiny">{doc.documentType.toLowerCase()}</span>
                      </TableCell>

                      <TableCell>
                        <Dropdown>
                          <DropdownTrigger>
                            <span
                              className={`${getPhaseClass(doc.phase)} cursor-pointer py-1 px-2 rounded-lg text-black font-medium`}
                            >
                              {doc.phase}
                            </span>
                          </DropdownTrigger>
                          <DropdownMenu>
                            {['Submitted', 'Follow-up', 'Declined', 'Accepted'].map((phase) => (
                              <DropdownItem
                                key={phase}
                                onPress={() => {
                                  updateDocumentStatus(doc.uuid, phase, doc.phase)
                                  setDocuments((prev) =>
                                    prev.map((item, idx) => (item.uuid === doc.uuid ? { ...item, phase } : item)),
                                  )

                                  // Mixpanel tracking for document status changes
                                  Mixpanel.track('Document status changed', {
                                    document_id: doc.uuid,
                                    previous_phase: doc.phase,
                                    new_phase: phase,
                                    location: 'Document hub',
                                  })
                                }}
                              >
                                <div className="flex flex-row justify-between items-center">
                                  <span
                                    className={`flex flex-row w-full justify-center text-center py-1 font-bold items-center gap-2 rounded-lg max-w-[100px] ${
                                          phase === 'Submitted'
                                        ? 'bg-[#C3E3E8]'
                                        : phase === 'Follow-up'
                                        ? 'bg-[#FACD94]'
                                        : phase === 'Declined'
                                        ? 'bg-[#FF97A9]'
                                        : 'bg-[#9DE1C3]'
                                    }`}
                                  >
                                    <span className="text-md font-medium text-black">{phase}</span>
                                  </span>
                                  {doc.phase === phase && <Icon icon="lucide:check" width={20} />}
                                </div>
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </TableCell>

                      {/* <TableCell className="capitalize">{doc.platform?.toLowerCase()}</TableCell> */}

                      <TableCell>{dayjs(doc.createdAt).format('MMM D, YYYY')}</TableCell>

                      <TableCell>{dayjs(doc.createdAt).format('MMM D, YYYY - h:mm A')}</TableCell>

                      <TableCell>
                        <Dropdown>
                          <DropdownTrigger>
                            <Button variant="light">
                              <Icon icon="lucide:more-vertical" />
                            </Button>
                          </DropdownTrigger>
                          <DropdownMenu aria-label="client Actions">
                            <DropdownItem
                              key="edit"
                              onPress={() => {
                                setDocumentToEdit(doc)
                                onEditOpenChange()

                                // Mixpanel tracking for Rename action
                                Mixpanel.track('Dropdown action clicked', {
                                  action: 'Rename',
                                  document_id: doc.uuid,
                                  location: 'Document hub',
                                })
                              }}
                            >
                              <span className="flex flex-row w-full font-bold items-center gap-2">
                                <Icon icon={'lucide:edit-3'} className={'text-success-500'} />
                                <span className={'text-md font-bold'}>Rename</span>
                              </span>
                            </DropdownItem>

                            <DropdownItem
                              key="duplicate"
                              showDivider
                              onPress={() => {
                                if (!currentLimitations?.canAddDocument) {
                                  openSubscriptionModal()
                                } else {
                                  setDocumentToEdit(doc)
                                  onDuplicateOpenChange()
                                }

                                // Mixpanel tracking for Duplicate action
                                Mixpanel.track('Dropdown action clicked', {
                                  action: 'Duplicate',
                                  document_id: doc.uuid,
                                  location: 'Document hub',
                                })
                              }}
                            >
                              <span className="flex flex-row w-full font-bold items-center gap-2">
                                <Icon icon={'lucide:book-copy'} className={'text-success-500'} />
                                <span className={'text-md font-bold'}>Duplicate</span>
                              </span>
                            </DropdownItem>

                            <DropdownItem
                              key="delete"
                              className="text-danger"
                              color="danger"
                              onPress={() => {
                                setDocumentToEdit(doc)
                                onDeleteOpenChange()

                                // Mixpanel tracking for Delete action
                                Mixpanel.track('Dropdown action clicked', {
                                  action: 'Delete',
                                  document_id: doc.uuid,
                                  location: 'Document hub',
                                })
                              }}
                            >
                              <span className="flex flex-row w-full font-bold items-center gap-2">
                                <Icon icon={'lucide:trash-2'} />
                                <span className={'text-md font-bold'}>Delete</span>
                              </span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
        <RenameDocumentModal
          isEditOpen={isEditOpen}
          onEditOpenChange={onEditOpenChange}
          documentToEdit={documentToEdit}
          updateDocuments={updateDocuments}
        />
        <DeleteDocumentModal
          documentToEdit={documentToEdit}
          onOpenDeleteDocumentChange={onDeleteOpenChange}
          openedDeleteDocument={isDeleteOpen}
          updateDocuments={updateDocuments}
        />
        <DuplicateDocumentModal
          openedDuplicateDocument={isDuplicateOpen}
          onOpenDuplicateDocumentChange={onDuplicateOpenChange}
          documentToEdit={documentToEdit}
          updateDocuments={updateDocuments}
        />
        {isOpenAssignClient && (
          <AssingCreateClientModal
            proposal={documentToEdit}
            isOpenAssignClient={isOpenAssignClient}
            onOpenAssignClient={onOpenedAssignClient}
            onAssignToClient={onAssignToClient}
          />
        )}
      </div>
    </>
  )
}
