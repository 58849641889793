'use client'

import * as React from 'react'
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Spacer,
  Spinner,
  Textarea,
  Tooltip,
} from '@nextui-org/react'

import { cn } from './cn'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Icon } from '@iconify/react'
import api from 'src/lib/api'
import toast from 'react-hot-toast'
import { useDropzone } from 'react-dropzone'
import { useSocketContextData } from 'src/context/socket'

interface CustomizePowerdocsProps {
  className?: string
  workspace: any
  setWorkspace: (ws: any) => void
  profile: any
  loadUser: () => void
}
const inActiveButtonClass = 'bg-transparent text-default-400 pl-5 pr-5'
const activeButtonClass = 'text-black-500 font-medium bg-white rounded-md  pl-5 pr-5'
const CustomizePowerdocs = React.forwardRef<HTMLDivElement, CustomizePowerdocsProps>(
  ({ className, workspace, setWorkspace, profile, ...props }, ref) => {
    const [loading, setLoading] = useState(false)
    const [fineTuneText, setFineTuneText] = useState<string>('')
    const [defaultDocLength, setDefaultDocLength] = useState<string>('Precise')
    const [formality, setFormality] = useState<string>('Semi-formal')
    const [modeOfAddress, setModeOfAddress] = useState<string>('Second-person tone')
    const [audianceType, setAudianceType] = useState<string>('General readers')
    const [language, setLanguage] = useState<string>('English')
    const [filesToUpload, setFilesToUpload] = useState<File[]>([])
    const [files, setFiles] = useState<any[]>([])
    const [uploading, setUpload] = useState<boolean>(false)
    const [uploaded, setUplaoded] = useState<boolean>(false)
    useEffect(() => {
      if (workspace) {
        setFineTuneText(workspace.fineTuneText ? workspace.fineTuneText : '')
        setAudianceType(workspace.audianceType ? workspace.audianceType : audianceType)
        setDefaultDocLength(workspace.defaultDocLength ? workspace.defaultDocLength : defaultDocLength)
        setFormality(workspace.formality ? workspace.formality : formality)
        setModeOfAddress(workspace.modeOfAddress ? workspace.modeOfAddress : modeOfAddress)
        setLanguage(workspace.language ? workspace.language : language)
        setFiles(workspace.files ? workspace.files : [])
      }
    }, [workspace])

    const updateCompany = async () => {
      setLoading(true)
      const formData = new FormData()
      formData.append('biography', workspace.biography)
      formData.append('contactPerson', workspace.contactPerson)
      formData.append('email', workspace.email)
      formData.append('phone', workspace.phone)
      formData.append('url', workspace.url)
      formData.append('address', workspace.address)
      formData.append('taxId', workspace.taxId)
      // this only to delete the logo in case the user decided to remove it, we only set empty logo when the previous value of the logo was set to AWS link
      if (workspace.logo !== '') formData.append('logo', workspace.logo)

      formData.append('fineTuneText', fineTuneText)
      formData.append('defaultDocLength', defaultDocLength)
      formData.append('audianceType', audianceType)
      formData.append('modeOfAddress', modeOfAddress)
      formData.append('formality', formality)
      formData.append('language', language)
      const res = await api.post(`/workspaces/${workspace.uuid}`, formData)
      if (res.data?.uuid) {
        setWorkspace(res.data)
      }
      setLoading(false)
      toast.success('Powerdocs custom details has been updated!', {
        position: 'bottom-center',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      })
    }
    const [training, setTraining] = useState<boolean>(false)
    const onDrop = useCallback(
      async (acceptedFiles: File[]) => {
        setFilesToUpload(acceptedFiles)
        trainingDiv.current?.classList.add('hidden')
        uploadDiv.current?.classList.remove('hidden')
        setUpload(true)
        const formData = new FormData()
        acceptedFiles.map((file: File) => formData.append('files', file))
        const res = await api.post(`/workspaces/files/${workspace.uuid}`, formData)
        setUpload(false)
        setFilesToUpload([])
        if (res.data?.data?.length > 0) {
          setFiles((prev) => [...prev, ...res.data.data])
        }
      },
      [workspace],
    )
    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      accept: {
        'application/pdf': ['.pdf'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      },
      maxFiles: 5 - files.length,
    })
    const { io } = useSocketContextData()
    const [reseting, setReseting] = useState<boolean>(false)

    useEffect(() => {
      if (io && !profile?.customizedPowerDocs) {
        io.emit('customize_powerdocs', {
          token: localStorage.getItem('accessToken'),
        })
      }
    }, [io, profile])
    const resetSettings = async () => {
      setReseting(true)
      await io.emitWithAck('resetModel', {
        token: localStorage.getItem('accessToken'),
        workspaceId: workspace.id,
      })
      setFiles([])
      setReseting(false)
    }
    const trainData = async () => {
      uploadDiv.current?.classList.add('hidden')
      trainingDiv.current?.classList.remove('hidden')
      setTraining(true)
      const res = await io.emitWithAck('trainWorkspace', {
        workspaceId: workspace.id,
        token: localStorage.getItem('accessToken'),
      })
      setTraining(false)
    }
    const uploadDiv = useRef<HTMLDivElement>(null)
    const trainingDiv = useRef<HTMLDivElement>(null)
    return (
      <>
        <div className="flex w-full pt-2 pb-6 px-2 pl-0">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold text-default-900 lg:text-3xl">Personalize Powerdocs AI</h1>
              <p className="text-small text-default-700 lg:text-medium">
                Set your preferred style and tone for document creation.
              </p>
            </div>
          </header>
        </div>
        <div ref={ref} className={cn('p-2 w-full pl-0 pt-2', className)} {...props}>
          <div className="flex flex-col gap-1">
          <span className={'flex flex-row items-center gap-2 text-sm'}>
            Document length 
            <Tooltip content="Select the document length preference" color="foreground">
              <Icon icon={'lucide:circle-help'} />
            </Tooltip>
          </span>
            <div className={'p-1 rounded-xl w-fit bg-default-100'}>
              {['Concise', 'Precise', 'Detailed'].map((item) => (
                <Button
                  size={'sm'}
                  className={defaultDocLength === item ? activeButtonClass : inActiveButtonClass}
                  onPress={() => setDefaultDocLength(item)}
                >
                  {item}
                </Button>
              ))}
            </div>
          </div>
          <Spacer y={2} />
          <div className="flex flex-col gap-1">
          <span className={'flex flex-row items-center gap-2 text-sm'}>
            Document language 
            <Tooltip content="Select the document language" color="foreground">
              <Icon icon={'lucide:circle-help'} />
            </Tooltip>
          </span>
            <div className={'p-1 rounded-xl w-fit bg-white'}>
              <Dropdown>
                <DropdownTrigger>
                  <Button variant="faded" className={'bg-white'}>
                    {language}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu aria-label="languages-list">
                  {['English', 'English (UK)', 'Spanish', 'French', 'German', 'Arabic', 'Japanese'].map((lang) => (
                    <DropdownItem
                      className={lang === language ? 'bg-default-300' : ''}
                      key={lang}
                      onPress={() => setLanguage(lang)}
                    >
                      {lang}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <Spacer y={2} />
          <div className="flex flex-col gap-1">
          <span className={'flex flex-row text-sm items-center gap-2'}>
            Formality 
            <Tooltip content="Select the formality level" color="foreground">
              <Icon icon={'lucide:circle-help'} />
            </Tooltip>
          </span>
            <div className={'p-1 rounded-xl w-fit bg-default-100'}>
              {['Informal', 'Semi-formal', 'Professional'].map((item) => (
                <Button
                  size={'sm'}
                  className={formality === item ? activeButtonClass : inActiveButtonClass}
                  onClick={() => setFormality(item)}
                >
                  {item}
                </Button>
              ))}
            </div>
          </div>
          <Spacer y={2} />
          <div className="flex flex-col gap-1">
          <span className={'flex flex-row text-sm items-center gap-2'}>
            Mode of address 
            <Tooltip content="Select the mode of address" color="foreground">
              <Icon icon={'lucide:circle-help'} />
            </Tooltip>
          </span>
            <div className={'p-1 rounded-xl w-fit bg-default-100'}>
              {['First-person tone', 'Second-person tone'].map((item) => (
                <Button
                  size={'sm'}
                  className={modeOfAddress === item ? activeButtonClass : inActiveButtonClass}
                  onClick={() => setModeOfAddress(item)}
                >
                  {item}
                </Button>
              ))}
            </div>
          </div>
          <Spacer y={2} />
          <div className="flex flex-col">
          <span className={'flex flex-row items-center gap-2 text-sm'}>
            Audience type 
            <Tooltip content="Select the audience type" color="foreground">
              <Icon icon={'lucide:circle-help'} />
            </Tooltip>
          </span>
            <div className={'p-1 rounded-xl w-fit bg-default-100'}>
              {['Experts', 'General readers', 'Technical users'].map((item) => (
                <Button
                  size={'sm'}
                  className={audianceType === item ? activeButtonClass : inActiveButtonClass}
                  onPress={() => setAudianceType(item)}
                >
                  {item}
                </Button>
              ))}
            </div>
          </div>
          <Spacer y={2} />
          <Textarea
            label={
              <span className={'flex flex-row text-sm items-center gap-2'}>
                Instructions for fine tuning{' '}
                <Tooltip
                  className="w-[200px] px-1.5 text-tiny text-default-600 rounded-small"
                  content="Enter your custom text here to fine-tune the AI agent’s responses. Provide examples or specific instructions for how you’d like the agent to behave or respond."
                >
                  <Icon icon={'lucide:circle-help'} />
                </Tooltip>
              </span>
            }
            placeholder="e.g. “I want Powerdocs AI to be more casual and humorous in its responses. Please avoid overly formal language.”"
            labelPlacement={'outside'}
            value={fineTuneText ?? ''}
            onChange={(e) => setFineTuneText(e.target.value)}
          />
          <Spacer y={2} />
          <div>
            <span className={'flex flex-row text-sm items-center gap-2'}>
              Learn from your past{' '}
              <Tooltip
                className="w-[200px] px-1.5 text-tiny text-default-600 rounded-small"
                content="Enter your custom text here to fine-tune the AI agent’s responses. Provide examples or specific instructions for how you’d like the agent to behave or respond."
              >
                <Icon icon={'lucide:circle-help'} />
              </Tooltip>
            </span>
            <span className={'text-xs text-default-400'}>
              {
                'Upload up to 5 past documents, and our AI will analyze your structure, tone, and style to create a personalized writing profile—ensuring every future document feels authentically yours.'
              }
            </span>

            <div
              {...getRootProps({
                className: 'flex flex-col gap-2 mt-2 bg-default-100 rounded-xl justify-center items-center',
              })}
            >
              <input {...getInputProps()} />
              {[...files, ...filesToUpload].length > 0 ? (
                <div className={'flex flex-col justify-center items-center gap-4 p-4 w-full'}>
                  <div className={'grid grid-cols-2 gap-4 w-full'}>
                    {[...files, ...filesToUpload].map((file: File | { fileName: string }) => {
                      return (
                        <div className={'bg-white flex rounded flex-row p-2'}>
                          <img src={'/assets/icons/file-pdf.png'} width={40} height={50} />
                          <div className={''}>
                            <p className={'text-xs'}>{(file as File).name ?? (file as IFile).fileName}</p>
                            <p className={'text-xs'}>PDF File</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className={'flex w-full justify-center items-center gap-1 hidden'} ref={uploadDiv}>
                    <div className={'flex flex-1 h-[1px] bg-black opacity-20'} />
                    <div
                      className={
                        'pt-1 pb-1 pr-6 pl-6 rounded-2xl top-[-4px] bg-warning-400 items-center justify-center flex gap-2 border-2 border-white'
                      }
                    >
                      {uploading && <Spinner size={'sm'} color={'white'} />}
                      <span className={'text-xs text-white'}>
                        {uploading ? 'Uploading files' : 'Document Uploaded'}
                      </span>
                    </div>
                    <div className={'flex flex-1 h-[1px] bg-black opacity-20'} />
                  </div>
                  <div className={'flex w-full justify-center items-center gap-1 hidden'} ref={uploadDiv}>
                    <div className={'flex flex-1 h-[1px] bg-black opacity-20'} />
                    <div
                      className={
                        'pt-1 pb-1 pr-6 pl-6 rounded-2xl top-[-4px] bg-warning-400 items-center justify-center flex gap-2 border-2 border-white'
                      }
                    >
                      {uploadDiv && <Spinner size={'sm'} color={'white'} />}
                      <span className={'text-xs text-white'}>{uploading ? 'Uploading' : 'Document Uploaded'}</span>
                    </div>
                    <div className={'flex flex-1 h-[1px] bg-black opacity-20'} />
                  </div>
                  <div className={'flex w-full justify-center items-center gap-1 hidden'} ref={trainingDiv}>
                    <div className={'flex flex-1 h-[1px] bg-black opacity-20'} />
                    <div
                      className={
                        'pt-1 pb-1 pr-6 pl-6 rounded-2xl top-[-4px] bg-warning-400 items-center justify-center flex gap-2 border-2 border-white'
                      }
                    >
                      {training && <Spinner size={'sm'} color={'white'} />}
                      <span className={'text-xs text-white'}>
                        {training ? 'Training mode' : 'Model Adapted to your style'}
                      </span>
                    </div>
                    <div className={'flex flex-1 h-[1px] bg-black opacity-20'} />
                  </div>
                  <div className={'flex flex-row flex-1 justify-between items-center p-4 w-full pt-0'}>
                    <Button
                      variant={'bordered'}
                      color={'default'}
                      style={{ borderColor: '#1c1c1c' }}
                      isDisabled={uploading || files.length === 5 || reseting}
                      onClick={() => {
                        open()
                      }}
                    >
                      <Icon icon={'lucide:plus'} color={'#1c1c1c'} />
                      <span>Upload file</span>
                    </Button>
                    <div className={'flex flex-row gap-4'}>
                      <Button
                        variant={'bordered'}
                        color={'default'}
                        style={{ borderColor: '#1c1c1c' }}
                        isDisabled={uploading || reseting}
                        onClick={resetSettings}
                      >
                        {reseting && <Spinner size={'sm'} />}
                        <span>{reseting ? 'Resetting settings' : 'Reset Settings'}</span>
                      </Button>
                      <Button
                        variant={'solid'}
                        color={'primary'}
                        style={{ borderColor: '#1c1c1c' }}
                        isDisabled={uploading || reseting}
                        onClick={trainData}
                      >
                        <span>Scan & Save now</span>
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={'flex flex-col justify-center items-center p-4'}>
                  <div className={'bg-white p-2 rounded-full mb-2'}>
                    <Icon icon={'lucide:cloud-upload'} />
                  </div>
                  <p className={'font-regular text-sm'}>
                    <span className={'pr-1 font-medium text-blue-400 cursor-pointer'} onClick={open}>
                      Click to upload or drag and drop
                    </span>
                    <span className={'font-light text-blue-400 '}>(max 5 files)</span>
                  </p>
                  <span className={'text-xs font-regular'}>PDF or DOCX (max. 5mo)</span>
                </div>
              )}
            </div>
          </div>{' '}
          <Spacer y={2} />
          <Button className="mt-5" color="secondary" size="md" radius="md" onClick={updateCompany} isDisabled={loading}>
            {loading ? (
              <span className={'flex flex-row justify-center items-center gap-2'}>
                Updating information <Spinner size={'sm'} color={'white'} />
              </span>
            ) : (
              'Update Details'
            )}{' '}
          </Button>
        </div>
      </>
    )
  },
)

CustomizePowerdocs.displayName = 'CompanyDetails'

export default CustomizePowerdocs
interface IFile {
  fileName: string
  id: number
  fileType: string
  fileId: string
}
