'use client'

import * as React from 'react'
import { Button, Input, Spacer, Spinner, Textarea, Tooltip } from '@nextui-org/react'

import { cn } from './cn'
import { useCallback, useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import api from 'src/lib/api'

interface CompanyDetailsProps {
  className?: string
  workspace: any
  setWorkspace: (w: any) => void
}
import { useDropzone } from 'react-dropzone'
import { RefreshCcwIcon, TrashIcon } from 'lucide-react'
import toast from 'react-hot-toast'

const CompanyDetails = React.forwardRef<HTMLDivElement, CompanyDetailsProps>(
  ({ className, workspace, setWorkspace, ...props }, ref) => {
    const [loading, setLoading] = useState(false)
    const [biography, setBiography] = useState<string>('')
    const [contactPerson, setContactPerson] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [url, setUrl] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [taxId, setTaxID] = useState<string>('')
    const [logo, setLogo] = useState<string>('')
    const [file, setFile] = useState<File>()

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
      setFile(acceptedFiles[0])
      if (acceptedFiles[0]) {
        const preview = URL.createObjectURL(acceptedFiles[0])
        setLogo(preview)
      }
    }, [])
    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      accept: {
        'image/*': ['.jpg', '.jpeg', '.png'],
      },
      maxFiles: 1,
    })
    useEffect(() => {
      if (workspace) {
        setBiography(workspace.biography ? workspace.biography : '')
        setContactPerson(workspace.contactPerson ? workspace.contactPerson : '')
        setEmail(workspace.email ? workspace.email : '')
        setPhone(workspace.phone ? workspace.phone : '')
        setUrl(workspace.url ? workspace.url : '')
        setAddress(workspace.address ? workspace.address : '')
        setTaxID(workspace.taxId ? workspace.taxId : '')
        setLogo(workspace.logo ? workspace.logo : '')
      }
    }, [workspace])

    const updateCompany = async () => {
      setLoading(true)
      const formData = new FormData()
      formData.append('biography', biography)
      formData.append('contactPerson', contactPerson)
      formData.append('email', email)
      formData.append('phone', phone)
      formData.append('url', url)
      formData.append('address', address)
      formData.append('taxId', taxId)
      formData.append('fineTuneText', workspace.fineTuneText)
      formData.append('defaultDocLength', workspace.defaultDocLength)
      formData.append('audianceType', workspace.audianceType)
      formData.append('modeOfAddress', workspace.modeOfAddress)
      formData.append('formality', workspace.formality)
      if (file) formData.append('file', file)
      // this only to delete the logo in case the user decided to remove it, we only set empty logo when the previous value of the logo was set to AWS link
      if (workspace.logo !== '') formData.append('logo', logo)
      const res = await api.post(`/workspaces/${workspace.uuid}`, formData)
      if (res.data?.uuid) {
        setWorkspace(res.data)
      }
      setLoading(false)
      toast.success('Company details has been updated!', {
        position: 'bottom-center',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      })
    }
    const deleteFile = async () => {
      setLogo('')
      setFile(undefined)
    }
    return (
      <>
        <div className="flex w-full pt-2 pb-6 px-2 pl-0">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold text-default-900 lg:text-3xl">Company details</h1>
              <p className="text-small text-default-700 lg:text-medium">
                Your company details appear on the title page, footer, and as contact information in your documents.
              </p>
            </div>
          </header>
        </div>
        <div ref={ref} className={cn('p-2 w-full', className)} {...props}>
          <Textarea
            label={
              <span className={'flex flex-row items-center gap-1'}>
                Info about your Company <Tooltip content="Provide a brief description about your company" color="foreground"><Icon icon={'lucide:circle-help'} /></Tooltip>
              </span>
            }
            placeholder="e.g., I am a freelance graphic designer specializing in branding and web design for small businesses. I offer logo creation, website design, and social media graphics, focusing on clean, modern aesthetics to help clients build a strong online presence."
            labelPlacement={'outside'}
            value={biography ? biography : ''}
            onChange={(e) => setBiography(e.target.value)}
          />
          <Spacer y={4} />
          <div>
            <span className={'flex flex-row items-center gap-1 text-small'}>
              Your Company Logo <Tooltip content="Upload your company logo" color="foreground"><Icon icon={'lucide:circle-help'} /></Tooltip>
            </span>
            <div
              {...getRootProps({
                className: 'flex flex-col gap-2 mt-2 bg-default-100 rounded-xl justify-center items-center',
              })}
            >
              <input {...getInputProps()} />
              <div className={'flex flex-col justify-center items-center p-4'}>
                {logo ? (
                  <>
                    <img src={logo} className={'w-[120px] mb-5'} />
                    <div className={'flex gap-2'}>
                      <Button variant="solid" color={'secondary'} className={''} onPress={open}>
                        <RefreshCcwIcon />
                        Select file
                      </Button>
                      <Button variant="bordered" color={'danger'} className={''} onPress={deleteFile}>
                        <TrashIcon />
                        Delete File
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={'bg-white p-2 rounded-full mb-2'}>
                      <Icon icon={'lucide:cloud-upload'} />
                    </div>
                    <p className={'font-regular text-sm'}>
                      <span className={'pr-1 font-medium text-blue-400 cursor-pointer'} onClick={open}>
                        Click to upload
                      </span>
                      or drag and drop
                    </p>
                    <span className={'text-xs font-regular'}>SVG, PNG, JPG or GIF (max.800x400px)</span>
                  </>
                )}
              </div>
            </div>
          </div>
          <Spacer y={4} />
          <div className="flex flex-wrap items-center gap-3 sm:flex-nowrap">
            <Input
              className="mt-2"
              label={
                <span className={'flex flex-row items-center gap-1'}>
                  Contact Person <Tooltip content="Enter the name of the contact person" color="foreground"><Icon icon={'lucide:circle-help'} /></Tooltip>
                </span>
              }
              labelPlacement={'outside'}
              type="text"
              placeholder="e.g. John Doe"
              value={contactPerson ? contactPerson : ''}
              onChange={(e) => setContactPerson(e.target.value)}
            />
            <Input
              className="mt-2"
              label={
                <span className={'flex flex-row items-center gap-1'}>
                  Email <Tooltip content="Enter the email address of the contact person" color="foreground"><Icon icon={'lucide:circle-help'} /></Tooltip>
                </span>
              }
              type="text"
              labelPlacement={'outside'}
              placeholder="e.g. john@acme.inc"
              value={email ? email : ''}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <Spacer y={4} />
          <div className="flex flex-wrap items-center gap-3 sm:flex-nowrap">
            <Input
              className="mt-2"
              label={
                <span className={'flex flex-row items-center gap-1'}>
                  Phone <Tooltip content="Enter the phone number for the contact person" color="foreground"><Icon icon={'lucide:circle-help'} /></Tooltip>
                </span>
              }
              labelPlacement={'outside'}
              type="text"
              placeholder="e.g. +1 (231) 4567-8903"
              value={phone ? phone : ''}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Input
              className="mt-2"
              label={
                <span className={'flex flex-row items-center gap-1'}>
                  Address <Tooltip content="Enter the company address" color="foreground"><Icon icon={'lucide:circle-help'} /></Tooltip>
                </span>
              }
              type="text"
              labelPlacement={'outside'}
              placeholder="e.g. 4015 Smith St"
              value={address ? address : ''}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <Spacer y={4} />
          <div className="flex flex-wrap items-center gap-3 sm:flex-nowrap">
            <Input
              className="mt-2"
              label={
                <span className={'flex flex-row items-center gap-1'}>
                  Company URL <Tooltip content="Enter the company website URL" color="foreground"><Icon icon={'lucide:circle-help'} /></Tooltip>
                </span>
              }
              labelPlacement={'outside'}
              type="text"
              placeholder="e.g. www.acme.inc"
              value={url ? url : ''}
              onChange={(e) => setUrl(e.target.value)}
            />
            <Input
              className="mt-2"
              label={
                <span className={'flex flex-row items-center gap-1'}>
                  Tax ID <Tooltip content="Enter the company tax identification number" color="foreground"><Icon icon={'lucide:circle-help'} /></Tooltip>
                </span>
              }
              type="text"
              labelPlacement={'outside'}
              placeholder="e.g. xx-xxxxxxx"
              value={taxId ? taxId : ''}
              onChange={(e) => setTaxID(e.target.value)}
            />
          </div>

          <Button className="mt-5" color="secondary" size="md" radius="md" onPress={updateCompany} isDisabled={loading}>
            {loading ? (
              <span className={'flex flex-row justify-center items-center gap-2'}>
                Updating information <Spinner size={'sm'} color={'white'} />
              </span>
            ) : (
              'Update Details'
            )}
          </Button>
        </div>
      </>
    )
  },
)

CompanyDetails.displayName = 'CompanyDetails'

export default CompanyDetails
