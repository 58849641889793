import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner } from '@nextui-org/react'
import React, { useState } from 'react'
import api from 'src/lib/api'
import Mixpanel from 'src/lib/mixpanel'
import { useWorkspaceContextData } from 'src/context/workspace'

export default function CreateNewWorkspace({
  isCreateOpen,
  onCreateOpenChange,
  loadUser,
}: {
  isCreateOpen: boolean
  onCreateOpenChange: (isOpen?: boolean) => void
  loadUser: () => Promise<void>
}) {
  const [creatingWorkspace, setCreatingWorkspace] = useState<boolean>(false)
  const [workspaceName, setWorkspaceName] = useState<string>('')
  const { setActiveWorkspace } = useWorkspaceContextData()
  const createWorkspace = async () => {
    setCreatingWorkspace(true)

    try {
      const res = await api.post('/workspaces/', {
        name: workspaceName,
      })
      // Track the workspace creation event
      Mixpanel.track('Workspace created', {
        workspace_name: workspaceName,
      })

      await loadUser()
      if (res?.data?.uuid) setActiveWorkspace(res.data.uuid)
    } catch (error) {
      console.error('Error creating workspace:', error)
    } finally {
      setCreatingWorkspace(false)
      setWorkspaceName('')
      onCreateOpenChange(false)
    }
  }
  return (
    <Modal isOpen={isCreateOpen} onOpenChange={onCreateOpenChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1"></ModalHeader>
            <ModalBody>
              <div className="flex flex-col grow gap-7">
                <div className="flex flex-col items-center">
                  <p className="text-2xl font-semibold">Create workspace</p>
                  <p className="text-base text-center">
                    Set up a new workspace, bring your team and clients on board, and keep your projects organized.
                  </p>
                </div>
                <div className="flex flex-col gap-3 items-center">
                  <Input
                    isRequired
                    type="text"
                    label="Workspace name"
                    placeholder="e.g. GreenLeaf workspace"
                    className="max-w-xs"
                    value={workspaceName}
                    onChange={(e) => setWorkspaceName(e.target.value)}
                    autoFocus={true}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button
                color="secondary"
                onPress={createWorkspace}
                isDisabled={workspaceName.length === 0 || creatingWorkspace}
              >
                {creatingWorkspace && <Spinner size={'sm'} />}
                {' Create'}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
