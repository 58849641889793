import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Button, Input, Link, Image, Spacer } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import api from 'src/lib/api'
import React, { useEffect, useState } from 'react'
import Mixpanel from 'src/lib/mixpanel'
import { useNavigate, useParams } from 'react-router-dom'
import { useWorkspaceContextData } from 'src/context/workspace'

export default function Invitation() {
  const { t } = useTranslation()

  const [firstname, setFirstName] = useState<string>('')
  const [lastname, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [accountType, setAccountType] = useState<string>('ourselves')
  const [error, setError] = useState<string>('')

  const [isVisible, setIsVisible] = React.useState<boolean>(false)
  const [isConfirmVisible, setIsConfirmVisible] = React.useState<boolean>(false)
  const { invitationUUID } = useParams()
  const toggleVisibility = () => setIsVisible(!isVisible)
  const toggleConfirmVisibility = () => setIsConfirmVisible(!isConfirmVisible)
  const navigate = useNavigate()
  const { setActiveWorkspace } = useWorkspaceContextData()

  useEffect(() => {
    if (invitationUUID) {
      loadEmail()
    }
  }, [invitationUUID])

  const loadEmail = () => {
    api
      .get(`/workspaces/invitation/${invitationUUID}`)
      .then((res) => {
        if (!res.data?.email) {
          return setError('Invalid invitation ID')
        }
        setEmail(res.data.email)
      })
      .catch((err) => {
        console.log('Login failed', err.message)
        setError('Login was not successful. ' + err.message)
        setTimeout(() => setError(''), 3000)
      })
  }
  
  const signup = async (e: any) => {
    e.preventDefault();
    if (confirmPassword !== password) {
      setError('Please verify your password!');
      return;
    }
  
    try {
      const res = await api.post('/auth/invitation/accept', { invitationUUID, firstname, lastname, email, password, accountType });
  
      if (res.data?.accessToken && res.data?.refreshToken) {
        localStorage.setItem('accessToken', res.data.accessToken);
        localStorage.setItem('refreshToken', res.data.refreshToken);
        localStorage.setItem('email', res.data.email);
        localStorage.setItem('user', JSON.stringify(res.data));
  
        // ✅ Ensure tracking completes before navigating
        await Promise.all([
          res.data.new ? Mixpanel.signup(res.data) : Promise.resolve(),
          Mixpanel.track('User signed up via invitation', {
            firstname,
            lastname,
            email,
            inviter: res.data.inviter || 'unknown'
          }),
          new Promise((resolve) => {
            if (window.gtag) {
              window.gtag('event', 'signup', res.data);
              setTimeout(resolve, 500); // ✅ Ensure GA4 has time to process
            } else {
              resolve();
            }
          })
        ]);
  
        setActiveWorkspace?.(res.data.defaultWorkspace ?? '');
        navigate('/');
      } else {
        setError('Signup was not successful. Try again.');
        setTimeout(() => setError(''), 3000);
      }
    } catch (err) {
      console.log('Signup failed', err.message);
      setError('Signup was not successful. ' + err.message);
      setTimeout(() => setError(''), 3000);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <div className="flex h-full w-full flex-col items-center justify-center">
        <div className="flex flex-col items-center pb-2">
          <Image
            key={'logo'}
            src="/img/strom-logo.png"
            alt="powerdocs logo"
            width={140}
            radius="none"
            className="mb-6"
          />
          <p className="text-xl font-medium">Welcome</p>
          <p className="text-small text-default-500">Create your account to get started</p>
        </div>
        <div className="mt-2 flex w-full max-w-md flex-col gap-4 rounded-large bg-content1 px-8 py-6 shadow-small">
          <form className="flex flex-col gap-2" onSubmit={(e) => e.preventDefault()}>
            <div className="flex flex-wrap items-center gap-2 sm:flex-nowrap">
              <Input
                isRequired
                onChange={(e) => setFirstName(e.target.value)}
                label="First name"
                name="firstname"
                placeholder="Your first name"
                type="text"
                variant="bordered"
              />
              <Input
                isRequired
                onChange={(e) => setLastName(e.target.value)}
                label="Last name"
                name="lastname"
                placeholder="Your last name"
                type="text"
                variant="bordered"
              />
            </div>
            <Input
              isRequired
              isDisabled
              value={email}
              label="Email address"
              name="email"
              placeholder="Your email"
              type="email"
              variant="bordered"
            />
            <Input
              isRequired
              endContent={
                <button type="button" onClick={toggleVisibility}>
                  {isVisible ? (
                    <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-closed-linear" />
                  ) : (
                    <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-bold" />
                  )}
                </button>
              }
              label="Password"
              name="password"
              isInvalid={confirmPassword.length > 0 && confirmPassword !== password}
              placeholder="Choose your password"
              onChange={(e) => setPassword(e.target.value)}
              type={isVisible ? 'text' : 'password'}
              variant="bordered"
            />
            <Input
              isRequired
              endContent={
                <button type="button" onClick={toggleConfirmVisibility}>
                  {isConfirmVisible ? (
                    <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-closed-linear" />
                  ) : (
                    <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-bold" />
                  )}
                </button>
              }
              label="Confirm password"
              name="confirmPassword"
              placeholder="Confirm your password"
              type={isConfirmVisible ? 'text' : 'password'}
              variant="bordered"
              isInvalid={confirmPassword.length > 0 && confirmPassword !== password}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Spacer y={0} />
            <Button color="secondary" type="submit" onClick={signup}>
              Sign Up
            </Button>
            {error && <p className={'text-danger text-center text-sm'}>{error}</p>}
            <p className="text-center text-small">
              By signing up, you agree to our&nbsp;
              <Link href="https://gavel.notion.site/Terms-bfea0d1a43814f1eb28b24db7bb05012" size="sm" isExternal>
                Terms
              </Link>
              &nbsp;&&nbsp;
              <Link
                href="https://gavel.notion.site/Privacy-policy-cdb12b39c4fc4be09368243c7a59fd07"
                size="sm"
                isExternal
              >
                Privacy
              </Link>
            </p>
          </form>
        </div>
      </div>
    </>
  )
}
