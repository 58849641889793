import axios from 'axios'

const getToken = () => localStorage.getItem('accessToken')

if (!localStorage.getItem('accessToken')) {
  console.log('No auth token found')
  // document.location.href = '/login'
}
export const baseURL =
  (import.meta.env.VITE_API_URL && import.meta.env.VITE_API_URL + 'api') || 'http://localhost:4001/api'
const api = axios.create({
  baseURL,
})

api.interceptors.request.use(
  (config) => {
    const accessToken = getToken()
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => Promise.reject(error),
)

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !(
        error.request?.responseURL?.includes('/supervisor/list') ||
        error.request?.responseURL?.includes('/signup') ||
        error.request?.responseURL?.includes('/invitation') ||
        error.request?.responseURL?.includes('/signin')
      )
    ) {
      // localStorage.clear()
      const keysToKeep = new Set([
        '_grecaptcha', // Google reCAPTCHA
        'iconify-count', // Iconify optimization
        'iconify-version',
        'iconify0',
      ])

      // Dynamically detect and preserve all Mixpanel (`mp_*`), Crisp chat (`crisp-client/session/*`), etc. keys
      Object.keys(localStorage).forEach((key) => {
        if (
          key.startsWith('mp_') ||
          key.startsWith('crisp-client/session/') ||
          key.startsWith('AMP_unsent_') ||
          key.startsWith('__mpq_') ||
          keysToKeep.has(key)
        ) {
          return // Keep these keys
        }
        localStorage.removeItem(key) // Remove everything else
      })

      if (
        document.location.href?.indexOf('/login') === -1 &&
        document.location.href.indexOf('/signup') === -1 &&
        document.location.href.indexOf('/account/reset') === -1 &&
        document.location.href.indexOf('/verify/user') === -1 &&
        document.location.href.indexOf('/invitation') === -1 &&
        !document.location.pathname?.startsWith('/document') &&
        !document.location.pathname?.startsWith('/proposal') &&
        !document.location.pathname?.startsWith('/offer') &&
        !document.location.pathname?.startsWith('/auth') &&
        !document.location.pathname?.startsWith('/quotation')
      ) {
        document.location.href = '/login'
      }
    }
    return Promise.reject(error)
  },
)

export default api
