import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Button, Input, Link, Image } from '@nextui-org/react'
import api from 'src/lib/api'
import React, { useEffect, useState } from 'react'
import Mixpanel from 'src/lib/mixpanel'
import { Icon } from '@iconify/react'
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom'

export default function ResetPassword() {
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [token, setToken] = useState<string>('')
  const [showCodeInput, setShowCodeInput] = useState<boolean>(false)
  const [showUpdatePassowrd, setShowUpdatePassword] = useState<boolean>(false)

  const [isVisible, setIsVisible] = React.useState(false)

  const toggleVisibility = () => setIsVisible(!isVisible)
  const navigate = useNavigate()

  const params = useParams()
  const checkToken = (token: string) => {
    setLoading(true)
    api
      .get(`/auth/check-token/${token}`)
      .then((res) => {
        console.log('Login', res.data)
        setLoading(false)
        if (res.data?.success) {
          setEmail(res.data?.email)
          setShowCodeInput(true)
          setShowUpdatePassword(true)
        } else {
          setError('Invalid Token')
          setShowCodeInput(false)
          setTimeout(() => setError(''), 3000)
        }
      })
      .catch((err) => {
        console.log('Login failed', err.message)
        setLoading(false)
        setShowCodeInput(true)
        setError('Invalid Token ' + err.message)
        setTimeout(() => setError(''), 3000)
      })
  }
  useEffect(() => {
    if (params.token) {
      setToken(params.token)
      setShowCodeInput(true)
      checkToken(params.token)
    }
  }, [params])

  const requestResetPassword = async (e: any) => {
    e?.preventDefault()
    setLoading(true)
    api
      .post('/auth/request-reset', { email })
      .then((res) => {
        console.log('Login', res.data)
        setLoading(false)
        if (res.data?.success) {
          setShowCodeInput(true)
        } else {
          setError('Login was not successful. Try again.')
          setShowCodeInput(false)
          setTimeout(() => setError(''), 3000)
        }
      })
      .catch((err) => {
        console.log('Login failed', err.message)
        setLoading(false)
        setShowCodeInput(true)
        setError('Login was not successful. ' + err.message)
        setTimeout(() => setError(''), 3000)
      })
  }
  const checkResetToken = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    api
      .post('/auth/verify-reset', { email, token })
      .then((res) => {
        setLoading(false)
        if (res.data?.success) {
          setShowUpdatePassword(true)
        } else {
          setError('Invalid token, Try again!')
          setShowCodeInput(true)
          setTimeout(() => setError(''), 3000)
        }
      })
      .catch((err) => {
        setLoading(false)
        setShowUpdatePassword(false)
        setError('Please verify your token, Invalid Token. ')
        setTimeout(() => setError(''), 3000)
      })
  }

  const updatePassword = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    api
      .post('/auth/update-password', { email, token, password })
      .then((res) => {
        setLoading(false)
        if (res.data?.accessToken) {
          localStorage.setItem('accessToken', res.data.accessToken)
          localStorage.setItem('refreshToken', res.data.refreshToken)
          localStorage.setItem('email', res.data.email)
          Mixpanel.login(res.data.uuid)
          navigate('/')
        } else {
          setError('Login was not successful. Try again.')
          setShowCodeInput(true)
          setTimeout(() => setError(''), 3000)
        }
      })
      .catch((err) => {
        setLoading(false)
        setShowUpdatePassword(true)

        setError('Login was not successful. ' + err.message)
        setTimeout(() => setError(''), 3000)
      })
  }

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <div className="flex h-full w-full flex-col items-center justify-center">
        <div className="flex flex-col items-center pb-6">
          <Image
            src="/img/strom-logo.png"
            alt="powerdocs logo"
            key={'logo'}
            width={140}
            radius="none"
            className="mb-6"
          />
          <p className="text-xl font-medium">Welcome Back</p>
          <p className="text-small text-default-500">Log in to your account to continue</p>
        </div>
        <div className="mt-2 flex w-full max-w-md flex-col gap-4 rounded-large bg-content1 px-8 py-6 shadow-small">
          {showUpdatePassowrd ? (
            <form className="flex flex-col gap-2" onSubmit={(e) => e.preventDefault()}>
              <Input
                endContent={
                  <button type="button" onClick={toggleVisibility}>
                    {isVisible ? (
                      <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-closed-linear" />
                    ) : (
                      <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-bold" />
                    )}
                  </button>
                }
                label="Password"
                name="password"
                placeholder="Enter your password"
                type={isVisible ? 'text' : 'password'}
                variant="bordered"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Input
                endContent={
                  <button type="button" onClick={toggleVisibility}>
                    {isVisible ? (
                      <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-closed-linear" />
                    ) : (
                      <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-bold" />
                    )}
                  </button>
                }
                label="Confirm Password"
                name="confirm-password"
                placeholder="Confirm your password"
                type={isVisible ? 'text' : 'password'}
                variant="bordered"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                isDisabled={loading || password !== confirmPassword || password.length < 8}
                color="secondary"
                type="submit"
                onClick={updatePassword}
                className="mt-2"
              >
                Update my password
              </Button>
              {error && <p className={'text-danger text-center text-sm'}>{error}</p>}
            </form>
          ) : (
            <form className="flex flex-col gap-2" onSubmit={(e) => e.preventDefault()}>
              <Input
                label="Email Address"
                name="email"
                placeholder="Enter your email"
                type="email"
                variant="bordered"
                isDisabled={showCodeInput || loading}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              {showCodeInput && (
                <Input
                  label="Reset Token"
                  name="resetToken"
                  placeholder="Enter your token here"
                  type="token"
                  variant="bordered"
                  onChange={(e) => setToken(e.target.value)}
                  value={token}
                  isDisabled={loading}
                />
              )}
              <Button
                isDisabled={loading}
                color="secondary"
                type="submit"
                onClick={showCodeInput ? checkResetToken : requestResetPassword}
                className="mt-2"
              >
                {showCodeInput ? 'Check Token' : 'Reset Password'}
              </Button>
              {error && <p className={'text-danger text-center text-sm'}>{error}</p>}
            </form>
          )}
          {!showCodeInput && (
            <p className="text-center text-small">
              Already have an account?&nbsp;
              <RouterLink to="/login">
                Log In
              </RouterLink>
            </p>
          )}
        </div>
      </div>
    </>
  )
}
