import { useState } from 'react';
import { Button, Divider, Input, Switch, Tooltip } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import { AIAssistant } from 'src/pages/ursPage/AIAssistant'

export default function DocSidebarContentAIAssistant({ onClose, doc, askAI, setLoading }) {
  const [shareLinkEnabled, setShareLinkEnabled] = useState(true);

  return (
    <div className="flex flex-col w-full h-full gap-4">
      <div className="flex flex-col sticky top-0 bg-white">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-medium">AI Assistant</h2>
          <Button isIconOnly variant="light" onPress={onClose} size="sm">
            <Icon icon="ic:round-close" className="text-gray-500 text-xl" />
          </Button>
        </div>
        <Divider className="my-2 h-[3px]" />
      </div>

      <div className="flex flex-col h-full items-center mb-4">
        <div className="mt-4 w-full h-full">
          <AIAssistant variant={'light'} doc={doc} askAI={askAI} setLoading={setLoading} />
        </div>
      </div>
    </div>
  );
}