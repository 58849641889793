import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner } from '@nextui-org/react'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import api from 'src/lib/api'
import Mixpanel from 'src/lib/mixpanel'
import { useNavigate } from 'react-router-dom'

export default function RenameDocumentModal({
  isEditOpen,
  onEditOpenChange,
  documentToEdit,
  updateDocuments,
} : {
  isEditOpen: boolean
  onEditOpenChange: (isOpen?: boolean) => void
  documentToEdit: any
  updateDocuments: () => void
}) {
  const [loading, setLoading] = useState<boolean>(false)
  const [documentName, setDocumentName] = useState<string>(documentToEdit?.name ?? '')
  const navigate = useNavigate()
  useEffect(() => {
    if (documentToEdit) {
      setDocumentName(documentToEdit?.title ?? '')
    }
  }, [documentToEdit])

  const renameDocument = useCallback(async () => {
    if (documentName.length === 0) return
    setLoading(true)
    let res
    try {
      res = await api.post('/urs/' + documentToEdit.uuid, {
        title: documentName,
      })
      Mixpanel.track('Document renamed', {
        document_id: documentToEdit?.uuid, // Include the document ID for traceability
        previous_name: documentToEdit?.title, // Track the old name of the document
        new_name: documentName, // Track the new name of the document
        location: 'Rename Document Modal', // Add the context of where this action occurred
      });
    } catch (error) {
      console.error('Error renaming document:', error)
    } finally {
      await updateDocuments?.()
      setLoading(false)
      setDocumentName('')
      onEditOpenChange()
    }
  }, [documentName, documentToEdit, updateDocuments, onEditOpenChange, loading])

  // Handle Enter (Submit) and Escape (Close) keys
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isEditOpen || loading) return

      if (event.key === 'Enter') {
        event.preventDefault()
        renameDocument()
      } else if (event.key === 'Escape') {
        event.preventDefault()
        onEditOpenChange(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isEditOpen, loading, renameDocument, onEditOpenChange])

  const inputRef = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    if (isEditOpen && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 200) // Give time for modal animation to complete
    }
  }, [isEditOpen])

  return (
    <Modal isOpen={isEditOpen} onOpenChange={onEditOpenChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1"></ModalHeader>
            <ModalBody>
              <div className="flex flex-col grow gap-7">
                <div className="flex flex-col items-center">
                  <p className="text-2xl font-semibold">Rename Document</p>
                  <p className="text-base text-center">Update document title.</p>
                </div>
                <div className="flex flex-col gap-3 items-center">
                <Input
                  ref={inputRef}
                  isRequired
                  type="text"
                  label="Project name"
                  placeholder="e.g. GreenLeaf Design"
                  className="max-w-xs"
                  value={documentName}
                  onChange={(e) => setDocumentName(e.target.value)}
                />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button color="secondary" onPress={renameDocument} isDisabled={documentName.length === 0 || loading}>
                {loading ? 'Renaming ' : 'Rename '}
                {loading && <Spinner size={'sm'} />}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
