import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Image, Spinner } from '@nextui-org/react'
import React, { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import api from 'src/lib/api'
import Mixpanel from 'src/lib/mixpanel'

export default function CallbackAuth() {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { social } = useParams()
  const navigate = useNavigate()
  const authGoogle = async () => {
    const code = searchParams.get('code')
    const res = await api.get('/auth/google/callback?code=' + code)
    if (res.data?.accessToken && res.data?.refreshToken) {
      localStorage.setItem('accessToken', res.data.accessToken)
      localStorage.setItem('refreshToken', res.data.refreshToken)
      localStorage.setItem('email', res.data.email)

      // ✅ Only track login, do NOT call Mixpanel.signup here
      Mixpanel.login(res.data.uuid)

      // ✅ Ensure tracking completes before navigating
      await Promise.all([
        Mixpanel.track('User logged in', { email: res.data.email }),
        new Promise((resolve) => {
          if (window.gtag) {
            window.gtag('event', 'login', { user_id: res.data.uuid })
            setTimeout(resolve, 500) // ✅ Ensure GA4 has time to process
          } else {
            resolve()
          }
        }),
      ])

      if (res.data.firstname === '' && res.data.lastname === '') {
        window.location = '/settings'
      } else {
        window.location = '/'
      }
    } else {
      // Track login failure
      await Mixpanel.track('Login failed', {
        social,
        error_message: 'No access or refresh token returned',
      })
    }
  }
  useEffect(() => {
    authGoogle()
  }, [])
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <div className="flex h-full w-full flex-col items-center justify-center px-3">
        <div className="flex flex-col items-center pb-6">
          <Image
            src="/img/powerdocs-logo.png"
            alt="powerdocs logo"
            key={'logo'}
            width={200}
            radius="none"
            className="mb-6"
          />
          <p className="text-xl font-medium">Welcome back</p>
          <p className="text-small text-default-500">Processing your login</p>
        </div>
        <div className="mt-2 flex w-full max-w-md flex-col gap-4 rounded-large bg-content1 px-8 py-6 shadow-small">
          <Spinner />
          <p className={'text-md text-center font-medium'}>Processing your login data</p>
        </div>
      </div>
    </>
  )
}
