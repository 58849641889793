import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Button, Image, Input, Link } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import api from 'src/lib/api'
import Mixpanel from 'src/lib/mixpanel'
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom'

export default function VerfiyUser() {
  const [token, setToken] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const params = useParams()
  useEffect(() => {
    if (params?.token) {
      setToken(params.token)
    }
  }, [params])
  const navigate = useNavigate()
  const verfiyUser = async () => {
    try {
      setLoading(true)
      const res = await api.post('/auth/verify', {
        verifyToken: token,
      })
      setLoading(false)
      if (res.data?.accessToken && res.data?.refreshToken) {
        localStorage.setItem('accessToken', res.data.accessToken)
        localStorage.setItem('refreshToken', res.data.refreshToken)
        localStorage.setItem('email', res.data.email)
        localStorage.setItem('user', JSON.stringify(res.data))
        Mixpanel.login(res.data.uuid)
        if (res.data.new) Mixpanel.signup(res.data)
        navigate('/')
      } else {
        setError('Verification was invalid. Try again.')
        setTimeout(() => setError(''), 3000)
      }
    } catch (error) {
      setError((error as Error).message)
      setLoading(false)
    }
  }
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <div className="flex h-full  w-full flex-col items-center justify-center">
        <div className="flex flex-col items-center pb-6">
          <Image
            src="/img/strom-logo.png"
            alt="powerdocs logo"
            key={'logo'}
            width={140}
            radius="none"
            className="mb-6"
          />
          <p className="text-xl font-medium">Verify your account</p>
          <p className="text-small text-default-500">Log in to your account to continue</p>
        </div>
        <div className="mt-2 flex w-full max-w-sm flex-col gap-4 rounded-large bg-content1 px-8 py-6 shadow-small">
          <form className="flex flex-col gap-3" onSubmit={(e) => e.preventDefault()}>
            <Input
              label="Verification Token"
              name="token"
              placeholder="Enter your token"
              type="text"
              variant="bordered"
              onChange={(e) => setToken(e.target.value)}
              value={token}
            />
            <Button color="secondary" type="submit" onClick={verfiyUser}>
              Verify my account
            </Button>
            <p className="text-center text-danger">{error}</p>
          </form>
          <p className="text-center text-small">
            Need to create an account?&nbsp;
            <RouterLink to="/signup">
              Sign Up
            </RouterLink>
          </p>
        </div>
      </div>
    </>
  )
}
