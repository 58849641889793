import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import {
  CardProps,
  Selection,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input,
  Spacer,
  Divider,
  AvatarGroup,
  Avatar,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from '@nextui-org/react'
import Mixpanel from 'src/lib/mixpanel'

import { Icon } from '@iconify/react'
import UserCell from './user-cell'
import { useWorkspaceContextData } from 'src/context/workspace'
import api from 'src/lib/api'

interface Member {
  id: number
  role: 'OWNER' | 'EDITOR' | 'VIEWER'
  user: {
    id: number
    firstname: string
    lastname: string
    email: string
  }
}
function getFirstItemOfSet(set: Selection) {
  for (const item of set) {
    if (item) {
      return item
    }
  }
  return 'VIEWER'
}
export default function Team(props: CardProps) {
  const { t } = useTranslation()

  const { activeWorkspace } = useWorkspaceContextData()

  const loadMembers = async (workspaceUUID: string) => {
    const res = await api.get(`/workspaces/${workspaceUUID}/members`)
    setMembers((res.data?.members as Member[]) ?? [])
    setInviations(res.data?.invitations ?? [])
  }
  useEffect(() => {
    if (activeWorkspace) {
      loadMembers(activeWorkspace)
    }
  }, [activeWorkspace])

  const [selectedKeys, setSelectedKeys] = React.useState<Selection>(new Set(['EDITOR']))

  const permissionLabels: Record<string, string> = {
    VIEWER: 'Can View',
    EDITOR: 'Can Edit',
  }
  const [members, setMembers] = useState<Member[]>([])
  const [invitations, setInviations] = useState<Member[]>([])

  const [userEmails, setUserEmails] = useState<string>('')
  const [inviting, setInviting] = useState<boolean>(false)
  const inviteUsers = async () => {
    setInviting(true)
    const res = await api.post(`/workspaces/${activeWorkspace}/invite`, {
      emails: userEmails.split(','),
      role: getFirstItemOfSet(selectedKeys).toString(),
    })
    await loadMembers(activeWorkspace)
    setInviting(false)
  }
  // Memoize the user list to avoid re-rendering when changing the selected keys
  const userList = React.useMemo(
    () => (
      <div className="mt-2 flex flex-col gap-2">
        {members.length == 0 && (
          <>
            <div className="flex flex-col items-center gap-2 text-xl text-center"><Icon icon="lucide:user-round-x" width="40" /><p>Your workspace hase no members yet.<br />Start building your team by clicking <span className="font-bold">Invite</span> above.</p></div>
          </>
        )}

        {members.length > 0 && (
          <>
            {members.map((member: Member, index: number) => (
              <>
                <UserCell
                  avatar="img/icon.png"
                  name={`${member.user.firstname} ${member.user.lastname}`}
                  permission={member.role}
                  />
                {index < members.length - 1 && <Divider />}
              </>
            ))}
          </>
        )}
      </div>
    ),
    [members],
  )
  // Memoize the invitations list to avoid re-rendering when changing the selected keys
  const invitationList = React.useMemo(
    () => (
      <div className="mt-2 flex flex-col gap-2">
        {invitations.map((invitation: any, index: number) => (
          <>
            <UserCell avatar="img/icon.png" name={`${invitation.email}`} permission={invitation.role} />
            {index < invitations.length - 1 && <Divider />}
          </>
        ))}
      </div>
    ),
    [invitations],
  )
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <div className="flex flex-col w-full h-screen p-3 overflow-y-auto">
        <div className="flex w-full pt-8 pb-6 px-2 2xl:px-8">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <div className="flex place-items-center">
                <h1 className="text-xl font-bold text-default-900 lg:text-3xl mr-2">Team</h1>
              </div>
              <p className="text-small text-default-700 lg:text-medium">
                Invite new users, set roles and permissions, and manage your team
              </p>
            </div>
          </header>
        </div>

        <div className="flex flex-col gap-3 w-full px-2 2xl:px-8 max-w-screen-md">
          <Card className="w-full" {...props}>
            <CardHeader className="justify-center px-6 pb-0 pt-6">
              <div className="flex flex-col items-center">
                <AvatarGroup isBordered size="sm">
                  <Avatar name="Jonathon" />
                  <Avatar name="Christine" />
                  <Avatar name="Gregory" />
                  <Avatar name="Monika" />
                </AvatarGroup>
                <Spacer y={2} />
                <h4 className="text-large">Invite team members</h4>
                <p className="text-center text-small text-default-500">Invite new members to your organization.</p>
              </div>
            </CardHeader>
            <Spacer y={8} />
            <CardBody>
              <div className="flex items-end gap-2">
                <Input
                  onChange={(e) => setUserEmails(e.target.value)}
                  endContent={
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          className="text-default-500"
                          endContent={
                            <span className="hidden sm:flex">
                              <Icon icon="solar:alt-arrow-down-linear" />
                            </span>
                          }
                          size="sm"
                          variant="light"
                        >
                          {Array.from(selectedKeys)
                            .map((key) => permissionLabels[key])
                            .join(', ')}
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        selectedKeys={selectedKeys}
                        selectionMode="single"
                        onSelectionChange={setSelectedKeys}
                      >
                        <DropdownItem key="EDITOR">Can edit</DropdownItem>
                        <DropdownItem key="VIEWER">Can view</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  }
                  label="Email address"
                  labelPlacement="outside"
                  placeholder="Separate multiple emails with commas"
                />
                <Button
                  color="secondary"
                  size="md"
                  onClick={() => {
                    // Track button click for inviting team members
                    Mixpanel.track("Invite member clicked", {
                      "user_id": localStorage.getItem('userId'), // Example, replace with actual user ID retrieval logic
                      "workspace_id": activeWorkspace, // Active workspace
                      "email_count": userEmails.length // Tracks number of emails being invited
                    });

                    // Function to invite users
                    inviteUsers();
                  }}
                  isDisabled={userEmails.length === 0 || inviting}
                >
                  {inviting && <Spinner color={'white'} className={'ml-1'} size={'sm'} />}Invite member
                </Button>
              </div>
              <Spacer y={4} />
              <p className="text-base">Active team members</p>
              {userList}
              <Spacer y={4} />
              {invitations.length > 0 && (
                <>
                  <p className="text-base">Pending invitations</p>
                  {invitationList}
                </>
              )}
            </CardBody>
            {/* <CardFooter className="justify-end gap-2">
              <Button size="sm" variant="flat">
                Copy Link
              </Button>
              <Button size="sm" variant="flat">
                Get Embed Code
              </Button>
            </CardFooter> */}
          </Card>
        </div>
      </div>
    </>
  )
}
