import api, { baseURL } from 'src/lib/api'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import {
  Image,
  Button,
  Card,
  useDisclosure,
  Spinner,
  Table,
  TableColumn,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { useNavigate, useParams } from 'react-router-dom'
import Mixpanel from 'src/lib/mixpanel'
import { useSocketContextData } from 'src/context/socket'
import { useWorkspaceContextData } from 'src/context/workspace'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import CreateUrdWindow from 'src/pages/ursList/CreateUrdWindow'
import toast from 'react-hot-toast'
import { SubscribeLimitDocument } from 'src/components/modals/subscribe-limit-document'
import { useSubscriptionContext } from 'src/context/subscription'
import RenameDocumentModal from 'src/pages/ursList/RenameDocumentModal'
import DeleteDocumentModal from 'src/pages/ursList/DeleteDocumentModal'
import DuplicateDocumentModal from 'src/pages/ursList/DuplicateDocumentModal'
dayjs.extend(relativeTime)

function UrsDocument({
  urs,
  removeDocument,
  index,
}: {
  index: number
  urs?: any
  removeDocument: (uuid: string) => void
}) {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const gradients = [
    'bg-gradient-to-br from-[#FFB457] to-[#FF705B]',
    'bg-gradient-to-br from-[#34D399] to-[#3B82F6]',
    'bg-gradient-to-br from-[#FBBF24] to-[#F59E0B]',
    'bg-gradient-to-br from-[#10B981] to-[#065F46]',
    'bg-gradient-to-br from-[#A78BFA] to-[#7C3AED]',
    'bg-gradient-to-br from-[#F472B6] to-[#DB2777]',
    'bg-gradient-to-br from-[#60A5FA] to-[#2563EB]',
    'bg-gradient-to-br from-[#EC4899] to-[#BE185D]',
    'bg-gradient-to-br from-[#F87171] to-[#B91C1C]',
    'bg-gradient-to-br from-[#C084FC] to-[#9333EA]',
    'bg-gradient-to-br from-[#F97316] to-[#EA580C]',
    'bg-gradient-to-br from-[#2DD4BF] to-[#0891B2]',
    'bg-gradient-to-br from-[#4ADE80] to-[#16A34A]',
    'bg-gradient-to-br from-[#818CF8] to-[#6366F1]',
    'bg-gradient-to-br from-[#FDE68A] to-[#F59E0B]',
    'bg-gradient-to-br from-[#F9A8D4] to-[#E11D48]',
    'bg-gradient-to-br from-[#A3E635] to-[#65A30D]',
    'bg-gradient-to-br from-[#38BDF8] to-[#0284C7]',
    'bg-gradient-to-br from-[#FDBA74] to-[#FB923C]',
    'bg-gradient-to-br from-[#FB7185] to-[#E11D48]',
  ]
  const { activeWorkspace } = useWorkspaceContextData()

  return (
    <>
      <Card
        isPressable
        shadow="none"
        onPress={() =>
          navigate(`/client/${urs.clientUUID}/document/${urs.uuid}/${urs?.documentType?.toLowerCase?.() ?? 'proposal'}`)
        }
        className="h-full"
      >
        <div className="overflow-visible p-0">
          <Image
            radius="lg"
            width="100%"
            alt={urs.title?.length > 0 ? urs.title : 'Untitled document'}
            className="z-0 w-full object-cover p-6 border-1"
            src="/img/urs-icon.png"
          />
        </div>
        <div className="flex-col w-full p-3 space-y-3 border-0">
          <div className="flex flex-col w-full text-left gap-1">
            <h4 className="font-medium text-small truncate">
              {urs.title?.length > 0 ? urs.title : 'Untitled document'}
            </h4>
            <p className="text-tiny truncate text-default-400">Edited {dayjs(urs.updatedAt).fromNow()}</p>
          </div>
          <div className="flex justify-end w-full gap-2">
            <Button
              onClick={() => {
                const popup = window.open(`${baseURL}/urs/export/${activeWorkspace}/${urs.uuid}`)
                if (popup) {
                  popup?.focus()
                  popup.addEventListener('unload', () => {
                    popup.close()
                  })
                }
              }}
              size="sm"
              isIconOnly
              radius="full"
              color="secondary"
              variant="bordered"
            >
              <Icon icon="solar:download-minimalistic-linear" width="18" />
            </Button>
            <Button
              onClick={async () => {
                setLoading(true)
                await removeDocument(urs.uuid)
                setLoading(false)
              }}
              size="sm"
              isIconOnly
              radius="full"
              color="danger"
              variant="bordered"
            >
              <Icon icon="solar:trash-bin-trash-linear" width="18" />
            </Button>
          </div>
        </div>
      </Card>
    </>
  )
}

export default function UrsList() {
  const { t } = useTranslation()
  const [initialized, setInitialized] = useState<boolean>(false)
  const { activeWorkspace } = useWorkspaceContextData()
  const [user, setUser] = useState(null)
  const loadUser = () => {
    const userString = localStorage.getItem('user')
    if (userString) {
      setUser(JSON.parse(userString))
    }
    api
      .get('/agencies/profile')
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setUser(res.data)
      })
      .catch((e) => {
        console.log('Error loading current profile')
      })
  }
  useEffect(() => {
    loadUser()
  }, [])
  const { io } = useSocketContextData()
  // Create new client modal and listen to "new" in the URL
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isSubscriptionModalOpen,
    onOpen: openSubscriptionModal,
    onClose: closeSubscriptionModal,
  } = useDisclosure()
  const handleOpen = () => {
    onOpen()
  }
  const params = useParams()
  const [project, setProject] = useState({})
  const loadProject = async () => {
    const res = await api.get(`/projects/${params.projectUUID}`)
    setProject(res.data)
  }

  useEffect(() => {
    if (params.projectUUID) {
      loadProject()
    }
  }, [params])

  const [creating, setCreating] = useState<boolean>(false)

  const createNewUrs = async () => {
    Mixpanel.track('Document creation intent', {
      location: 'Projects',
    })
    onOpen()
  }

  const [sendingEmail, setSendingEmail] = useState<boolean>(false)
  const resendVerification = async () => {
    if (!sendingEmail) {
      setSendingEmail(true)
      const res = await api.post('/auth/resend')
      setSendingEmail(false)
      toast.success('Verification email has been sent successfully')
    }
  }
  const { currentLimitations } = useSubscriptionContext()
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure()
  const { isOpen: isDuplicateOpen, onOpen: onDuplicateOpen, onOpenChange: onDuplicateOpenChange } = useDisclosure()
  const { isOpen: isEditOpen, onOpen: onOpenEditProject, onOpenChange: onEditOpenChange } = useDisclosure()
  const [documentToEdit, setDocumentToEdit] = useState<any>({})
  const updateDocuments = async () => {
    return loadProject()
  }
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <CreateUrdWindow
        isOpen={isOpen}
        onClose={onClose}
        projectUUID={params.projectUUID}
        showSubscriptionError={() => {
          setTimeout(() => openSubscriptionModal(), 1000)
        }}
      />
      <SubscribeLimitDocument isOpen={isSubscriptionModalOpen} onClose={closeSubscriptionModal} />
      <div className="flex flex-col w-full h-screen p-3 max-w-screen-xl">
        <div className="flex w-full pt-8 pb-6 px-2 2xl:px-8">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold text-default-900 lg:text-3xl">{project?.name ?? ''}</h1>
            </div>
          </header>
        </div>

        {user && !user?.isActive && (
          <div className={'flex w-full p-2 mb-3 rounded-xl bg-warning-100'}>
            <p className={'text-sm flex flex-row gap-1 flex-1 '}>
              {'⚠️ You need to verify your account in order to use this feature.'}
              <p className={'cursor-pointer underline'} onClick={resendVerification}>
                Click here to resend verification email.
              </p>
            </p>
            {sendingEmail && <Spinner size={'sm'} />}
          </div>
        )}
        {!Array.isArray(project?.URS) || project?.URS?.length == 0 ? (
          <div className="flex flex-col items-center place-content-center grow gap-3 px-3">
            {/* <Image
              src="/img/empty-state-chat.png"
              alt="no projects available"
              width={400}
              radius="none"
              className="mb-8"
            /> */}
            <Icon icon="lucide:file-x-2" width="80" className="text-default-600" />
            <div className="flex flex-col">
              <p className="text-2xl text-center font-semibold">No documents available</p>
              <p className="text-base text-center">Get started by creating your first document</p>
            </div>
            <Button
              onClick={() => {
                createNewUrs()
              }}
              color="secondary"
              size="lg"
              radius="md"
              isDisabled={
                creating || !user?.isActive || (!currentLimitations?.isPro && !currentLimitations?.canAddDocument)
              }
              startContent={
                creating ? (
                  <Spinner size={'md'} />
                ) : (
                  <Icon className="flex-none" icon="solar:add-circle-linear" width={20} />
                )
              }
            >
              Create first document
            </Button>
          </div>
        ) : (
          <div className="flex gap-13 w-full flex-col gap-2 pb-10 max-w-full max-h-full px-2 2xl:px-8 overflow-y-auto">
            <div className={'flex justify-between flex-row justfiy-center align-middle items-center'}>
              <h2 className={'text-2xl'}>Documents</h2>
              <Button
                onClick={() => {
                  createNewUrs()
                }}
                color="secondary"
                size="md"
                radius="md"
                isDisabled={
                  creating || !user?.isActive || (!currentLimitations?.isPro && !currentLimitations?.canAddDocument)
                }
                startContent={
                  creating ? (
                    <Spinner size={'md'} />
                  ) : (
                    <Icon className="flex-none" icon="solar:add-circle-linear" width={20} />
                  )
                }
              >
                Create document
              </Button>
            </div>
            <Table aria-label="Document List">
              <TableHeader>
                <TableColumn className={'text-black bg-[#C3E3E8]'}>Document name</TableColumn>
                <TableColumn className={'text-black bg-[#C3E3E8]'}>Type</TableColumn>
                <TableColumn className={'text-black bg-[#C3E3E8]'}>Action</TableColumn>
              </TableHeader>
              <TableBody>
                {project.URS?.map((item) => (
                  <TableRow key={item.uuid}>
                    <TableCell
                      className={'cursor-pointer flex flex-row gap-2 items-center align-middle'}
                      onClick={() =>
                        navigate(
                          `/client/${project.client?.uuid}/document/${item.uuid}/${
                            item?.documentType?.toLowerCase?.() ?? 'proposal'
                          }`,
                        )
                      }
                    >
                      <Icon
                        icon={item.documentType === 'PROPOSAL' ? 'lucide:heart-handshake' : 'lucide:file-signature'}
                        width={25}
                        className={'opacity-20'}
                      />{' '}
                      <div className={'flex flex-col'}>
                        <span className={'text-black text-md'}>{item?.title}</span>
                        <span className={'text-grey text-xs opacity-20'}>
                          {`Edited ${dayjs(item?.updatedAt).fromNow()}`}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell className={'capitalize'}>{item.documentType?.toLowerCase()}</TableCell>
                    <TableCell>
                      <Dropdown
                        classNames={{
                          content: 'rounded-xl border border-1 border-success-500 min-w-[150p]',
                        }}
                      >
                        <DropdownTrigger>
                          <Button
                            variant={'light'}
                            className={'p-0 m-0 data-[hover=true]:bg-transparent'}
                            onClick={() => {}}
                          >
                            <Icon icon={'lucide:more-vertical'} />
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="client Actions">
                          <DropdownItem
                            key="edit"
                            onClick={() => {
                              setDocumentToEdit(item)
                              onEditOpenChange()
                            }}
                          >
                            <span className="flex flex-row w-full font-bold items-center gap-2">
                              <Icon icon={'lucide:edit-3'} className={'text-success-500'} />
                              <span className={'text-md font-bold'}>Rename</span>
                            </span>
                          </DropdownItem>
                          <DropdownItem
                            key="duplicate"
                            showDivider
                            onClick={() => {
                              setDocumentToEdit(item)
                              onDuplicateOpenChange()
                            }}
                          >
                            <span className="flex flex-row w-full font-bold items-center gap-2">
                              <Icon icon={'lucide:book-copy'} className={'text-success-500'} />
                              <span className={'text-md font-bold'}>Duplicate</span>
                            </span>
                          </DropdownItem>
                          <DropdownItem
                            key="delete"
                            className="text-danger"
                            color="danger"
                            onClick={() => {
                              setDocumentToEdit(item)
                              onDeleteOpenChange()
                            }}
                          >
                            <span className="flex flex-row w-full font-bold items-center gap-2">
                              <Icon icon={'lucide:trash-2'} />
                              <span className={'text-md font-bold'}>Delete</span>
                            </span>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
        <RenameDocumentModal
          isEditOpen={isEditOpen}
          onEditOpenChange={onEditOpenChange}
          documentToEdit={documentToEdit}
          updateDocuments={updateDocuments}
        />
        <DeleteDocumentModal
          documentToEdit={documentToEdit}
          onOpenDeleteDocumentChange={onDeleteOpenChange}
          openedDeleteDocument={isDeleteOpen}
          updateDocuments={updateDocuments}
        />
        <DuplicateDocumentModal
          openedDuplicateDocument={isDuplicateOpen}
          onOpenDuplicateDocumentChange={onDuplicateOpenChange}
          documentToEdit={documentToEdit}
          updateDocuments={updateDocuments}
        />
      </div>
    </>
  )
}
