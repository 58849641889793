'use client'

import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Card, CardBody, CardProps, Chip } from '@nextui-org/react'
import { Icon } from '@iconify/react'

import { cn } from './cn'

export type ActionCardProps = CardProps & {
  icon: string
  title: string
  color?: 'primary' | 'secondary' | 'warning' | 'danger'
  description: string
  href?: string
  isExternal?: boolean
  chip?: string
}

const ActionCard = React.forwardRef<HTMLDivElement, ActionCardProps>(
  ({ color, title, icon, description, children, className, href, isExternal, chip, ...props }, ref) => {
    const target = isExternal === true ? '_blank' : '_self'
    const colors = React.useMemo(() => {
      switch (color) {
        case 'primary':
          return {
            card: 'border-default-200',
            iconWrapper: 'bg-primary-50 border-primary-100',
            icon: 'text-primary',
          }
        case 'secondary':
          return {
            card: 'border-secondary-100',
            iconWrapper: 'bg-secondary-50 border-secondary-100',
            icon: 'text-secondary',
          }
        case 'warning':
          return {
            card: 'border-warning-500',
            iconWrapper: 'bg-warning-50 border-warning-100',
            icon: 'text-warning-600',
          }
        case 'danger':
          return {
            card: 'border-danger-300',
            iconWrapper: 'bg-danger-50 border-danger-100',
            icon: 'text-danger',
          }
        default:
          return {
            card: 'border-default-200',
            iconWrapper: 'bg-default-50 border-default-100',
            icon: 'text-default-500',
          }
      }
    }, [color])

    if (href) {
      if (isExternal) {
        return (
          <Card
            ref={ref}
            isPressable
            className={cn('border-small', colors?.card, className)}
            shadow="sm"
            onClick={() => window.open(href, target, 'noopener,noreferrer')}
            {...props}
          >
            <CardBody className="flex h-full flex-row items-start gap-3 p-4">
              <div className={cn('item-center flex rounded-medium border p-2', colors?.iconWrapper)}>
                <Icon className={colors?.icon} icon={icon} width={24} />
              </div>
              <div className="flex flex-col">
                <div className="text-medium flex align-center">
                  {title}
                  {chip && (
                    <Chip color="secondary" size="sm" variant="solid" className="ml-2">
                      {chip}
                    </Chip>
                  )}
                </div>
                <p className="text-small text-default-500">{description || children}</p>
              </div>
            </CardBody>
          </Card>
        );
      } else {
        return (
          <Card
            ref={ref}
            as={RouterLink}
            to={href}
            isPressable
            className={cn('border-small', colors?.card, className)}
            shadow="sm"
            {...props}
          >
            <CardBody className="flex h-full flex-row items-start gap-3 p-4">
              <div className={cn('item-center flex rounded-medium border p-2', colors?.iconWrapper)}>
                <Icon className={colors?.icon} icon={icon} width={24} />
              </div>
              <div className="flex flex-col">
                <div className="text-medium flex align-center">
                  {title}
                  {chip && (
                    <Chip color="secondary" size="sm" variant="solid" className="ml-2">
                      {chip}
                    </Chip>
                  )}
                </div>
                <p className="text-small text-default-500">{description || children}</p>
              </div>
            </CardBody>
          </Card>
        );
      }
    } else {
      return (
        <Card
          ref={ref}
          isPressable
          className={cn('border-small', colors?.card, className)}
          shadow="sm"
          // onClick={() => window.open(href, target, 'noopener,noreferrer')}
          {...props}
        >
          <CardBody className="flex h-full flex-row items-start gap-3 p-4">
            <div className={cn('item-center flex rounded-medium border p-2', colors?.iconWrapper)}>
              <Icon className={colors?.icon} icon={icon} width={24} />
            </div>
            <div className="flex flex-col">
              <div className="text-medium flex align-center">
                {title}
                {chip && (
                  <Chip color="secondary" size="sm" variant="solid" className="ml-2">
                    {chip}
                  </Chip>
                )}
              </div>
              <p className="text-small text-default-500">{description || children}</p>
            </div>
          </CardBody>
        </Card>
      )
    }
  }
);

ActionCard.displayName = 'ActionCard'

export default ActionCard
